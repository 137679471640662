import { addMinutes, subMinutes, isBefore, isWithinInterval } from 'date-fns/fp'
import { anyToDate } from './date'

/**
 * @usage
 *  isNowBefore(reducedTimeToAuth(new Date(start)))
 *  isNowBefore(timeToCheckoutAfterCheckin(new Date(checkin)))
 * @return boolean
 */
export function isNowBefore(schedule) {
  const isBeforeSchedule = isBefore(schedule)
  return isBeforeSchedule(new Date())
}

/**
 * @returns boolean
 */
export function isNowBeforeExtended(schedule, minutes) {
  return isBeforeExtended(schedule, minutes)(new Date())
}

/**
 * @returns boolean
 */
export function isNowBeforeReduced(schedule, minutes) {
  return isBeforeReduced(schedule, minutes)(new Date())
}

/**
 * @returns CurriedFn1
 */
export function isBeforeExtended(schedule, minutes) {
  const extended = addMinutes(minutes)
  return isBefore(extended(anyToDate(schedule)))
}

/**
 * @returns CurriedFn1
 */
export function isBeforeReduced(schedule, minutes) {
  const reduced = subMinutes(minutes)
  return isBefore(reduced(anyToDate(schedule)))
}

/**
 * @usage inInGraceInterval(schedule, 10, 5)(new Date())
 * @return CurriedFn1
 */
export function isInGraceInterval(schedule, fromMM, toMM) {
  const subStart = subMinutes(fromMM)
  const addEnd = addMinutes(toMM)
  const date = anyToDate(schedule)
  return isWithinInterval({
    start: subStart(date),
    end: addEnd(date)
  })
}
