<template lang="html">
  <div>
    <section id="firebaseui-auth-container"></section>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import { firebaseAuth } from '@/firebaseInit'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
// import { enablePersistence } from '@/fbApi'
//@todo const firebaseResolver = lang => require('@/firebaseui/firebaseui__' + lang)
//@todo const firebaseui = firebaseResolver('es_419')

import * as firebaseui from 'firebaseui'
// remove comment to activate es: import * as firebaseui from 'firebaseui/dist/npm__es_419'
import 'firebaseui/dist/firebaseui.css'

import 'firebase/compat/messaging'
import { displayNotification, setupPushService } from '@/services/fbPushCtl'
import {
  userInfo,
  claimsInTokenResult,
  uidInUser,
  isNewUser,
  isEmailVerified,
  emailInClaims
} from '@/components/user/firebaseUserCtl'
import { isAuthorizedEmail } from '@/components/user/authCtl'
const ui = new firebaseui.auth.AuthUI(firebase.auth())

export default {
  mounted() {
    // It is the first call to auth & route
    const initUserSession = this.initUserSession
    const setCompletedSections = this.setCompletedSections
    const setMessaging = this.setMessaging
    const setPushEnabled = this.setPushEnabled
    const uiConfig = {
      callbacks: {
        // Begin signInSuccessWithAuthResult
        signInSuccessWithAuthResult: function(authResult) {
          // Return true if it will make the redirection
          // Return false if it will delegate the routing to the vue router
          const user = authResult.user
          setPushEnabled(false)
          user
            .getIdTokenResult()
            .then(async tokenResult => {
              const claims = claimsInTokenResult(tokenResult)
              initUserSession(userInfo(user, claims))
              setCompletedSections(uidInUser(user)).then(() => {
                const isCorpEmail = isAuthorizedEmail(emailInClaims(claims))
                if (isCorpEmail && isNewUser(authResult)) {
                  /**
                   * Sign up
                   */
                  user
                    .sendEmailVerification()
                    .then(() => {
                      firebaseAuth.signOut().then(() => {
                        window.location.assign('/onverification')
                        return true
                      })
                    })
                    .catch(error => {
                      firebaseAuth.signOut().then(() => {
                        console.log('Can not send it', error)
                      })
                    })
                } else if (isCorpEmail && !isEmailVerified(claims)) {
                  /**
                   * On email verification
                   */
                  window.location.assign('/onverification')
                  return true
                } else if (!(isCorpEmail && isEmailVerified(claims))) {
                  /**
                   * Not a valid email or not email verified
                   */
                  window.location.assign('/login')
                  return true
                } else {
                  // Succesful access then, it delegates to the vue router
                  return false
                }
              })
              // END setCompletedSections

              // BEGIN Setup firebase messaging
              const messaging = firebase.messaging()
              setMessaging(messaging)
              await setupPushService(messaging, setPushEnabled)
              messaging.onMessage(payload => {
                displayNotification(payload)
              })
              // END Setup firebase messaging
            })
            .catch(error => {
              console.log('Can not get the token result', error)
            })
          // It runs synchronous in the mean time &
          // it is waiting for the previous promise
          // to resolve to decide which route will
          // be decided by the vue router.
          return false
        }
        // End signInSuccessWithAuthResult
      },
      // signInOptions: ['microsoft.com', ...]
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
      ],
      // Required to enable one-tap sign-up credential helper.
      credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
      // Terms of service url.
      // tosUrl: 'https://policies.google.com/terms',
      // Privacy policy url.
      // privacyPolicyUrl: 'https://policies.google.com/privacy'
    }

    ui.start('#firebaseui-auth-container', uiConfig)
  },
  methods: {
    ...mapActions('user', [
      'initUserSession',
      'setCompletedSections',
      'setPushEnabled'
    ]),
    setMessaging(messaging) {
      Vue.prototype.$messaging = messaging
    }
    // getRelationship() {
    //   const elements = this.$route.name ? this.$route.name.split('/') : []
    //   return (elements.length > 0 && elements[1]) || 'a'
    // }
  }
}
</script>

<style lang="scss">
.firebaseui-link {
  color: #ff1744 !important;
}

.mdl-button {
  border-radius: 20px;
}

.mdl-button--primary.mdl-button--primary {
  color: #ff1744;
}

.mdl-button--raised.mdl-button--colored:active,
.mdl-button--raised.mdl-button--colored:focus:not(:active),
.mdl-button--raised.mdl-button--colored:hover {
  background-color: #ff1744;
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  background-color: #e1000f;
}

.mdl-button.mdl-button--colored {
  color: #e1000f;
}

.mdl-button--raised.mdl-button--colored {
  background: #e1000f;
  color: #fff;
}

.mdl-shadow--2dp {
  box-shadow: 0 0 0 0 rgb(0 0 0), 0 0 0 0 rgb(0 0 0), 0 0 0 0 rgb(0 0 0);
}

.firebaseui-subtitle,
.firebaseui-title {
  color: #e1000f;
  font-size: 1.75rem !important;
  font-weight: 500;
  line-height: 26px;
  font-family: Raleway, sans-serif !important;
}

.firebaseui-textfield.mdl-textfield .firebaseui-input {
  font-size: 20px;
}

.firebaseui-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  left: 10px;
  right: 10px;
  padding-right: 40px;
}
</style>
