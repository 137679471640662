import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { getControlPath } from './apiControl'

/**
 * Add new access stage
 */
export async function addNewAccessStage(stage, request, rs, ou, aid) {
  const path = getControlPath(rs, ou, aid, 'covid19pass', 'active')
  return await saveNextStageDoc(path, request, stage)
}

async function saveNextStageDoc(path, request, stage) {
  const ref = stageRef(path, request.id)
  return await ref.set(nextStageData(stage))
}

function stageRef(path, reqId) {
  return firebase
    .firestore()
    .doc(`${path}/${reqId}`)
    .collection('workflow')
    .doc()
}

/**
 * The final document to be saved
 *
 * @param {Object} stage The prepared next stage
 * @param {Object} request The request of the next stage (pending)
 * @param {Vuex}  rootGetters (pending)
 * @returns The document to be saved
 */
function nextStageData(stage) {
  return {
    ...stage,
    effective: firebase.firestore.Timestamp.fromDate(new Date())
  }
}
