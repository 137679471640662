import { format } from 'date-fns/fp'

/**
 |-------------------------------------------------------------------- 
 | ISO Strings are converted from local to World Standard Time GMT-6
 | If you have a local date: new Date('2021-11-02T13:30')
 | It will be converted to: '2021-11-02T19:30:00.000Z'
 |--------------------------------------------------------------------
 */

export function isValidDate(dateStr) {
  return !isNaN(new Date(dateStr).getDate())
}

/**
 * @param {string} datetimeStr
 * @return {string} ISO Date: '2021-11-02'
 * @reviewed ok
 */
export function extractDate(datetimeStr) {
  const index = datetimeStr.lastIndexOf('T')
  return datetimeStr.substr(0, index)
}

/**
 * It extracts the exact date & time w/o the time zone
 * It is useful for the date time pickers
 * @param {Date} utcDate
 * @return {string}
 */
export function extractDateTimeFromUtc(utcDate) {
  const isoDateFormat = format("yyyy-MM-dd'T'HH:mm")
  return isoDateFormat(utcDate)
}

/**
 * @param {string} date '2021-10-28'
 * @param {string} time '08:10'
 * @returns '2021-10-28T08:10'
 */
export function toLocalPlainDateTime(date, time) {
  return `${date}T${time}`
}

export function toLocalTimeFromPlainStr(plainDateStr) {
  // '2021-11-02T18:50'
  const hh = plainDateStr.substr(11, 12)
  const mm = plainDateStr.substr(14, 15)
  return `${hh}:${mm}`
}

export function anyToDate(date) {
  return typeof date === 'string' ? new Date(date) : date
}
