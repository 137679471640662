import { isWithinTimeToCheckin } from '../pass/checkinTimeControl'
import { ON_PASS2_STAGE } from '../status/statusControl'
import { isPass2Stage } from '../workflow/stagesControl'

export function pass2Status(stage, period) {
  return {
    match: isPass2Stage(stage) && isWithinTimeToCheckin(period),
    status: ON_PASS2_STAGE
  }
}
