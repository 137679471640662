import firebase from 'firebase/compat/app'
import firebaseConfig from '@/services/fbConfig'

// Initialize firebase
export const firebaseApp = firebase.initializeApp(firebaseConfig)

// Auth
import { getAuth, onAuthStateChanged } from 'firebase/auth'
export const firebaseAuth = getAuth(firebaseApp)
// User session
export const getFirebaseUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      firebaseAuth,
      user => {
        unsubscribe()
        resolve(user)
      },
      reject
    )
  })
}

// Pro and debug sections
const isDebugOn = process.env.NODE_ENV !== 'production' ? true : false

// Analytics
import { getAnalytics } from 'firebase/analytics'
export const firebaseAnalytics = getAnalytics()

// App Check
if (isDebugOn) {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN =
    process.env.VUE_APP_FIREBASE_APPCHECK_DEBUG_TOKEN
}
const srcHost = `${window.location.protocol}//${window.location.host}`
const isOnPreview =
  srcHost === 'https://access-vid--op-stage-2-bdwqdhzm.web.app'
const {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider
} = require('firebase/app-check')
initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider(
    isOnPreview
      ? process.env.VUE_APP_FB_RECAPTCHA_PREVIEW_KEY
      : process.env.VUE_APP_FB_RECAPTCHA_KEY
  ),
  isTokenAutoRefreshEnabled: true // auto-refresh.
})

// Emulators
import { getApp } from 'firebase/app'
// import { connectAuthEmulator } from 'firebase/auth'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
if (isDebugOn) {
  // Storage

  // Functions
  const functions = getFunctions(getApp())
  connectFunctionsEmulator(functions, 'localhost', 5001)

  // Auth
  // const auth = getAuth()
  // connectAuthEmulator(auth, 'http://localhost:9099')

  // Firestore
  const db = getFirestore()
  connectFirestoreEmulator(db, 'localhost', 8080)
}
