import { setStorageName } from '../storageNameOperator'

export const namespaced = true

export const state = {
  uop: {
    uid: null,
    appId: null,
    oid: null
  }
}

export const getters = {
  uid(state) {
    return state.uop.uid
  },
  ou(state) {
    return state.uop.oid
  }
}

export const mutations = {
  // SESSION
  SET_USER_SESSION(state, uop) {
    if (uop.uid && uop.appId) {
      setStorageName(uop.uid, uop.appId)
      state['uop'].uid = uop.uid
      state['uop'].appId = uop.appId
    }
  },
  UPDATE_ORG(state, oid) {
    state['uop'].oid = oid
  }
}

export const actions = {
  initSession({ commit }, fbAccount) {
    commit('SET_USER_SESSION', fbAccount)
  },
  updateOrg({ commit }, oid) {
    commit('UPDATE_ORG', oid)
  }
}
