import {
  isAuthStage,
  isAuth3Stage,
  isNextStage
} from '@/components/requests/workflow/stagesControl'
import { isCodeActive } from '@/components/requests/pass/passControl'

import {
  stageStatusWithAlert,
  wasStagePassActive,
  stageStatus,
  REJECTED_REQUEST,
  ON_UNMATCH_STATUS_ERROR,
  APPROVED_STAGE
} from '@/components/requests/status/statusControl'
import {
  wasStageCheckedOutOnTime,
  wasStageCheckedInOnTime,
  wasStageCheckedOutInUnexpectedTime,
  wasStageCheckedInUnexpectedTime
} from '@/components/requests/status/statusTimeControl'

import {
  wasAuthRejectedOnLate,
  wasAuth3ApprovedOnLate,
  wasAuthApprovedOnLate,
  wasAuthRejectedOnTime,
  wasAuth3ApprovedOnTime,
  wasAuthApprovedOnTime
} from '@/components/requests/status/statusTimeControl'

import { periodIn, resourceInRequest } from '../reqsControl'
import { nextAuth3Status } from './nextAuth3Status'
import { nextAuth1Status, nextAuth2Status } from './nextAuthsStatus'
import { someAuthsRejectedStatus } from './currentSomeAuthsRejectedStatus'
import { pass1Status } from './currentPass1Status'
import { nextCheckinStatus } from './nextCheckinStatus'
import { checkinCanceledSatus } from './currentCanceledStatus'
import { pass2Status } from './currentPass2Status'
import { checkinStatus } from './currentCheckinStatus'
import { checkoutStatus } from './currentCheckoutStatus'
import { expiredStatus } from './currentExpiredStatus'
import { nextPendingStatus } from './nextPendingStatus'

/**
 * It returns which status had each stage and
 * the current status of the passports.
 *
 * @param {Object} stage The evaluated state
 * @param {Object} item The request
 * @returns A string representing the status
 */
export function statusOf(stage, item) {
  const isAuth = isAuthStage(stage)
  const isAuth3 = isAuth3Stage(stage)
  // @todo wasAuth3ApprovedOnTime && wasAuthApprovedOnTime depends in its order.
  //       make wasAuthApprovedOnTime independent of the order. Just change
  //       the way it identifies the auth. In place of type === 'auth' (that
  //       it includes also auth3) you should replace it by auth1 and auth2
  if (
    wasAuth3ApprovedOnTime(item, stage, isAuth3) ||
    wasAuthApprovedOnTime(item, stage, isAuth) ||
    wasAuthRejectedOnTime(item, stage, isAuth) ||
    wasStageCheckedInOnTime(item, stage, isAuth) ||
    wasStageCheckedOutOnTime(item, stage, isAuth)
  ) {
    return stageStatus(stage)
  } else if (
    wasAuth3ApprovedOnLate(item, stage, isAuth3) ||
    wasAuthApprovedOnLate(item, stage, isAuth) ||
    wasAuthRejectedOnLate(item, stage, isAuth) ||
    wasStageCheckedInUnexpectedTime(item, stage, isAuth) ||
    wasStageCheckedOutInUnexpectedTime(item, stage, isAuth)
  ) {
    return stageStatusWithAlert(stage)
  } else if (wasStagePassActive(stage, isAuth)) {
    return isCodeActive(stage, item) ? APPROVED_STAGE : REJECTED_REQUEST
  } else {
    return REJECTED_REQUEST
  }
}

/**
 * It returns the current workflow status
 *
 * @param {Object} request The required request
 * @param {Object} isNextStage(store, stageName, requestData)
 * @returns A string representing the status
 */
export function currentStatus(current, request, isNextStage) {
  // const current = currentStageOf(request.workflow.stages)
  const period = periodIn(resourceInRequest(request))

  const someAuthsRejected = someAuthsRejectedStatus(request)
  console.log(someAuthsRejected.match ? 'someAuthsRejected' : '')
  if (someAuthsRejected.match) return someAuthsRejected.status

  const nextAuth3 = nextAuth3Status(request, period, isNextStage)
  console.log(nextAuth3.match ? 'nextAuth3' : '')
  if (nextAuth3.match) return nextAuth3.status

  // const nextAuths = nextAuthsStatus(request, period, isNextStage)
  // if (nextAuths.match) return nextAuths.status

  const nextAuth2 = nextAuth2Status(request, period, isNextStage)
  console.log(nextAuth2.match ? 'nextAuth2' : '')
  if (nextAuth2.match) return nextAuth2.status

  const nextAuth1 = nextAuth1Status(request, period, isNextStage)
  console.log(nextAuth1Status.match ? 'nextAuth1Status' : '')
  if (nextAuth1.match) return nextAuth1.status

  const currentPass1 = pass1Status(current, period)
  console.log(currentPass1.match ? 'currentPass1' : '')
  if (currentPass1.match) return currentPass1.status

  const nextCheckin = nextCheckinStatus(current, request, period, isNextStage)
  console.log(nextCheckinStatus.match ? 'nextCheckinStatus' : '')
  if (nextCheckin.match) return nextCheckin.status

  const currentCheckinCanceled = checkinCanceledSatus(current)
  console.log(currentCheckinCanceled.match ? 'currentCheckinCanceled' : '')
  if (currentCheckinCanceled.match) return currentCheckinCanceled.status

  const currentPass2 = pass2Status(current, period)
  console.log(pass2Status.match ? 'pass2Status' : '')
  if (currentPass2.match) return currentPass2.status

  const currentCheckin = checkinStatus(current)
  console.log(currentCheckin.match ? 'currentCheckin' : '')
  if (currentCheckin.match) return currentCheckin.status

  const currentCheckout = checkoutStatus(current)
  console.log(currentCheckout.match ? 'currentCheckout' : '')
  if (currentCheckout.match) return currentCheckout.status

  const requestExpired = expiredStatus(current, period)
  console.log(requestExpired.match ? 'requestExpired' : '')
  if (requestExpired.match) return requestExpired.status

  const reolvingNext = nextPendingStatus(current, period)
  console.log(reolvingNext.match ? 'reolvingNext' : '')
  if (reolvingNext.match) return reolvingNext.status

  console.log('ON_UNMATCH_STATUS_ERROR')
  return ON_UNMATCH_STATUS_ERROR
}

export function isCurrentStatusRejected(current, item, store) {
  return currentStatus(current, item, isNextStage(store)) === REJECTED_REQUEST
}
