import {
  isCheckinStage,
  isCheckoutStage
} from '@/components/requests/workflow/stagesControl'
import {
  wasOnExpectedTimeToAuth,
  wasOnLateTimeToAuth
} from '../auths/authsTimeControl'
import {
  wasOnExpectedTimeToAuth3,
  wasOnLateTimeToAuth3
} from '../auths/auths3TimeControl'
import {
  wasInUnexpectedTimeToCheckin,
  wasOnExpectedTimeToCheckin,
  wasOnLateTimeToCheckin
} from '../pass/checkinTimeControl'
import {
  wasOnExpectedTimeToCheckout,
  wasOnLateTimeToCheckout,
  wasInUnexpectedTimeToCheckout
} from '../pass/checkoutTimeControl'
import { isAuthSuccessful } from './statusControl'
import { periodIn, resourceInRequest } from '../reqsControl'

export const wasAuthApprovedOnTime = (item, stage, isAuth) => {
  return (
    isAuth &&
    wasOnExpectedTimeToAuth(
      periodIn(resourceInRequest(item)),
      stage.effective
    ) &&
    isAuthSuccessful(stage)
  )
}

export const wasAuth3ApprovedOnTime = (item, stage, isStageAuth3) => {
  return (
    isStageAuth3 &&
    wasOnExpectedTimeToAuth3(
      periodIn(resourceInRequest(item)),
      stage.effective
    ) &&
    isAuthSuccessful(stage)
  )
}

export const wasAuthRejectedOnTime = (item, stage, isAuth) => {
  return (
    isAuth &&
    wasOnExpectedTimeToAuth(
      periodIn(resourceInRequest(item)),
      stage.effective
    ) &&
    !isAuthSuccessful(stage)
  )
}

export const wasAuthApprovedOnLate = (item, stage, isAuth) => {
  return (
    isAuth &&
    wasOnLateTimeToAuth(periodIn(resourceInRequest(item)), stage.effective) &&
    isAuthSuccessful(stage)
  )
}

export const wasAuth3ApprovedOnLate = (item, stage, isAuth) => {
  return (
    isAuth &&
    wasOnLateTimeToAuth3(periodIn(resourceInRequest(item)), stage.effective) &&
    isAuthSuccessful(stage)
  )
}

export const wasAuthRejectedOnLate = (item, stage, isAuth) => {
  return (
    isAuth &&
    wasOnLateTimeToAuth(periodIn(resourceInRequest(item)), stage.effective) &&
    !isAuthSuccessful(stage)
  )
}

export const wasStageCheckedInOnTime = (item, stage, isAuth) => {
  return (
    !isAuth &&
    isCheckinStage(stage) &&
    wasOnExpectedTimeToCheckin(
      periodIn(resourceInRequest(item)),
      stage.effective
    )
  )
}

export const wasStageCheckedInOnLate = (item, stage, isAuth) => {
  return (
    !isAuth &&
    isCheckinStage(stage) &&
    wasOnLateTimeToCheckin(periodIn(resourceInRequest(item)), stage.effective)
  )
}

export const wasStageCheckedInUnexpectedTime = (item, stage, isAuth) => {
  return (
    !isAuth &&
    isCheckinStage(stage) &&
    wasInUnexpectedTimeToCheckin(
      periodIn(resourceInRequest(item)),
      stage.effective
    )
  )
}

export const wasStageCheckedOutOnTime = (item, stage, isAuth) => {
  return (
    !isAuth &&
    isCheckoutStage(stage) &&
    wasOnExpectedTimeToCheckout(
      periodIn(resourceInRequest(item)),
      stage.effective
    )
  )
}

export const wasStageCheckedOutOnLate = (item, stage, isAuth) => {
  return (
    !isAuth &&
    isCheckoutStage(stage) &&
    wasOnLateTimeToCheckout(periodIn(resourceInRequest(item)), stage.effective)
  )
}

export const wasStageCheckedOutInUnexpectedTime = (item, stage, isAuth) => {
  const p = periodIn(resourceInRequest(item))
  return (
    !isAuth &&
    isCheckoutStage(stage) &&
    wasInUnexpectedTimeToCheckout(p.start, p.end, stage.effective)
  )
}
