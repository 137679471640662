import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
// @todo Check this
// export function enablePersistence() {
//   store.enablePersistence({ synchronizeTabs: true })
// }
export function enablePersistence() {
  firebase
    .firestore()
    .enablePersistence()
    .catch(err => {
      if (err.code == 'failed-precondition') {
        console.log(
          'Multiple tabs open, persistence can only be enabled in one tab at a a time.'
        )
      } else if (err.code == 'unimplemented') {
        console.log(
          'You can not work in offline mode. Your device does not support it.'
        )
      }
    })
}

export async function getCompletedSections(uid) {
  try {
    const doc = await firebase
      .firestore()
      .collection('app')
      .doc(uid)
      .get()

    return doc.exists ? doc.data().s : []
  } catch (error) {
    return []
  }
}

export async function setCompletedSections(uid, sections) {
  await firebase
    .firestore()
    .collection('app')
    .doc(uid)
    .set({ s: sections }, { merge: true })
}
