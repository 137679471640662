import { STAGE_NOT_ON_ALERT } from '@/components/requests/status/stageOnAlert'
import { resolveTimeToAuthStatus } from './nextAuth'
import { resolveTimeToAuth3Status } from './nextAuth3'
import { resolveTimeToCheckinStatus } from './nextCheckin'
import { resolveTimeToCheckoutStatus } from './nextCheckout'

/**
 *
 * @param {Object} next stage description data
 * @param {Object} request request data
 */
export function alertLevelByTime(next, period) {
  const nextAuthStatus = resolveTimeToAuthStatus(period, next)
  if (nextAuthStatus.matched) return nextAuthStatus.value
  const nextAuth3Status = resolveTimeToAuth3Status(period, next)
  if (nextAuth3Status.matched) return nextAuth3Status.value
  const nextCheckinStatus = resolveTimeToCheckinStatus(period, next)
  if (nextCheckinStatus.matched) return nextCheckinStatus.value
  const nextCheckoutStatus = resolveTimeToCheckoutStatus(period, next)
  if (nextCheckoutStatus.matched) return nextCheckoutStatus.value
  return STAGE_NOT_ON_ALERT
}
