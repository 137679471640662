import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

export async function getUserPolicyCondition(
  condition,
  topic,
  request,
  context
) {
  const path = getUserPolicyPath(request.uid, topic, context)
  const query = firebase
    .firestore()
    .collection(path)
    .where(condition, '==', true)
  return await query.get()
}

export async function getGeneralPolicyByTopic(topic, context) {
  return (await getGeneralPolicy(context)).data()[topic]
}

export async function getGeneralPolicy(context) {
  const docPath = `${getPolicyDocPath(context)}`
  const policyRef = firebase.firestore().doc(docPath)
  return await policyRef.get()
}

export function getUserPolicyPath(uid, topic, { rs, ou, area }) {
  return `policies/${rs}/${ou}/${area}/${topic}/${uid}/items`
}

export function getPolicyDocPath({ rs, ou }) {
  return `policies/${rs}/${ou}/general`
}
