import { getJsonItem } from '@/store/nativeStorage'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

export const namespaced = true

export const state = {
  resources: null
}

export const getters = {
  getAllActiveResources(state) {
    return (state.resources && state.resources['active']) || []
  }
}

export const mutations = {
  SET_ACTIVE_RESOURCES(state, active) {
    state.resources = {
      active
    }
  }
}

export const actions = {
  async getActiveResources({ commit }) {
    const localItem = getJsonItem('resources')
    const activeResources =
      localItem && !!localItem.resources
        ? localItem.resources.active
        : await (
            await firebase
              .firestore()
              .collection('descriptions/resources/active')
              .orderBy('seq')
              .get()
          ).docs.map(doc => doc.data())

    commit('SET_ACTIVE_RESOURCES', activeResources)
  }
}
