import {
  warningLateDurationToCheckin,
  warningEarlierDurationToCheckin,
  dangerLateDurationToCheckin,
  expectedDurationToCheckin,
  untilDangerLateDurationToCheckin
} from '@/cfg'
import { isNowBeforeReduced, isInGraceInterval } from '@/micro/time/dateCompare'
import {
  addMinutes,
  subMinutes,
  isBefore,
  isWithinInterval,
  isAfter
} from 'date-fns/fp'
import { wasInFromStart } from '@/components/requests/reqsTimeControl'
import {
  paramToScheduleStart,
  whenRequestWasCreatedAt,
  resourceInRequest,
  scheduleEndInResource
} from '../reqsControl'

export function isBetweenCreatedAndAndCheckin(request) {
  const start = whenRequestWasCreatedAt(request)
  const end = scheduleEndInResource(resourceInRequest(request))
  const isIn = isWithinInterval({ start, end })
  return isIn(new Date())
}

export function isBetweenAnyAndCheckin(scheduleStart) {
  const isBeforeCheckin = isBefore(checkinExtended(new Date(scheduleStart)))
  return isBeforeCheckin(new Date())
}

export function isAfterExtendedTimeToCheckin(period) {
  const isAfterCheckin = isAfter(checkinExtended(new Date(period.start)))
  return isAfterCheckin(new Date())
}

function checkinExtended(start) {
  const untilDanger = addMinutes(untilDangerLateDurationToCheckin())
  return untilDanger(start)
}

export function isWithinTimeToCheckin(startOrPeriod) {
  return wasWithinTimeToCheckin(startOrPeriod, new Date())
}

export function wasWithinTimeToCheckin(startOrPeriod, effective) {
  const scheduleStart = paramToScheduleStart(startOrPeriod)
  const isInGrace = isInGraceInterval(
    scheduleStart,
    warningEarlierDurationToCheckin(),
    warningLateDurationToCheckin() + dangerLateDurationToCheckin()
  )
  return isInGrace(effective)
}

/**
 * @param {{start: Date, end: Date}} period
 * @returns boolean
 */
export function isInUnexpectedTimeToCheckin(period) {
  return wasInUnexpectedTimeToCheckin(period, new Date())
}

/**
 * @param {{start: Date, end: Date}} period
 * @returns boolean
 */
export function wasInUnexpectedTimeToCheckin(period, effective) {
  return (
    wasOnWarningEarlierToCheckin(period, effective) ||
    wasOnLateTimeToCheckin(period, effective)
  )
}

/**
 * @returns boolean
 */
export function isOnEarlierTimeToCheckin(period) {
  return wasOnWarningEarlierToCheckin(period, new Date())
}

/**
 * @returns boolean
 */
export function isOnLateTimeToCheckin(period) {
  return wasOnLateTimeToCheckin(period, new Date())
}

/**
 * @returns boolean
 */
export function wasOnLateTimeToCheckin(period, effective) {
  return (
    wasOnWarningLateToCheckin(period, effective) ||
    wasOnDangerTimeToCheckin(period, effective)
  )
}

/**
 * @param {{start: Date, end: Date}} period
 * @return boolean
 */
export function isOnExpectedTimeToCheckin(period) {
  return wasOnExpectedTimeToCheckin(period, new Date())
}

/**
 * @param {{start: Date, end: Date}} period
 * @return boolean
 */
export function isBeforeWarningTimeToCheckin(period) {
  return wasBeforeWarningEarlierToCheckin(period, new Date())
}

/**
 * @param {{start: Date, end: Date}} period
 * @param {string | Date} effective
 * @return boolean
 */
export function wasBeforeWarningEarlierToCheckin(period, effective) {
  return beforeWarningEarlierSegmentToCheckin(period.start)(effective)
}

/**
 * @param {{start: Date, end: Date}} period
 * @param {string | Date} effective
 * @return boolean
 */
export function wasOnWarningEarlierToCheckin(period, effective) {
  return wasInFromStart(warningEarlierSegmentToCheckin, period, effective)
}

/**
 * @param {{start: Date, end: Date}} period
 * @param {string | Date} effective
 * @return boolean
 */
export function wasOnExpectedTimeToCheckin(period, effective) {
  return wasInFromStart(expectedSegmentToCheckin, period, effective)
}

/**
 * @param {{start: Date, end: Date}} period
 * @return boolean
 */
export function isOnWarningLateToCheckin(period) {
  return wasOnWarningLateToCheckin(period, new Date())
}

/**
 * @param {{start: Date, end: Date}} period
 * @param {string | Date} effective
 * @return boolean
 */
export function wasOnWarningLateToCheckin(period, effective) {
  return wasInFromStart(warningLateSegmentToCheckin, period, effective)
}

/**
 * @return boolean
 */
export function isOnDangerTimeToCheckin(period) {
  return wasOnDangerTimeToCheckin(period, new Date())
}

/**
 * @param {{start: Date, end: Date}} period
 * @param {string | Date} effective
 * @return boolean
 */
export function wasOnDangerTimeToCheckin(period, effective) {
  return wasInFromStart(dangerSegmentToCheckin, period, effective)
}

/**
 * @param {Date} scheduleStart
 * @return CurriedFn1
 */
function warningEarlierSegmentToCheckin(scheduleStart) {
  const expected = expectedDurationToCheckin()
  const start = subMinutes(expected + warningEarlierDurationToCheckin())
  const end = subMinutes(expected)
  return isWithinInterval({
    start: start(scheduleStart),
    end: end(scheduleStart)
  })
}

/**
 * @param {Date} scheduleStart
 * @return CurriedFn1
 */
function beforeWarningEarlierSegmentToCheckin(scheduleStart) {
  const warningStart = subMinutes(
    expectedDurationToCheckin() + warningEarlierDurationToCheckin()
  )
  return isBefore(warningStart(scheduleStart))
}

/**
 * @param {Date} scheduleStart
 * @return CurriedFn1
 */
function expectedSegmentToCheckin(scheduleStart) {
  const toleranceInMin = expectedDurationToCheckin()
  return isInGraceInterval(scheduleStart, toleranceInMin, toleranceInMin)
}

/**
 * @param {Date} scheduleStart
 * @return CurriedF1
 */
function warningLateSegmentToCheckin(scheduleStart) {
  const expected = expectedDurationToCheckin()
  const start = addMinutes(expected)
  const end = addMinutes(expected + warningLateDurationToCheckin())
  return isWithinInterval({
    start: start(scheduleStart),
    end: end(scheduleStart)
  })
}

/**
 * @param {Date} scheduleStart
 * @return CurriedF1
 */
function dangerSegmentToCheckin(scheduleStart) {
  const expected = expectedDurationToCheckin()
  const from = addMinutes(expected + warningLateDurationToCheckin())
  const to = addMinutes(
    expected + warningLateDurationToCheckin() + dangerLateDurationToCheckin()
  )
  return isWithinInterval({
    start: from(scheduleStart),
    end: to(scheduleStart)
  })
}

export function isNowBeforeGracePeriodToCheckin(scheduleStart) {
  return isNowBeforeReduced(
    scheduleStart,
    expectedDurationToCheckin() + warningEarlierDurationToCheckin()
  )
}
