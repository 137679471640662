import { capitalized } from '@/micro/text'
import { isStageApproved } from '../status/statusControl'

// Dictionary
export const initStageName = () => 'init'
export const authStageName = number => `auth${number}`
export const authStageType = () => 'auth'
export const checkinStageName = () => 'checkin'
export const checkoutStageName = () => 'checkout'
export const endStageName = () => 'end'
export const notAllowedStageName = () => 'catch'

// Present
const isStage = stageId => stage => stage !== undefined && stage.id === stageId
export const isInitStage = isStage('init')
export const isAuth1Stage = isStage('auth1')
export const isAuth2Stage = isStage('auth2')
export const isAnyPreviousAuthBeforeAccess = stage =>
  isStage('auth1')(stage) || isStage('auth2')(stage)
export const isAuth3Stage = isStage('auth3')
export const isPass1Stage = isStage('pass1')
export const isPass2Stage = isStage('pass2')
export const isCheckinStage = isStage('checkin')
export const isCheckoutStage = isStage('checkout')
export const isEndStage = isStage('end')
export const isNotAllowedStage = isStage('catch')

export const stageId = stage => stage.id
export const defaultNextStageId = stage => stage.next

export const isNextInStage = isStage => stage => isStage(stage.next)
export const isNextStageEnd = isNextInStage(isEndStage)
export const isStageIdEnd = id => id === endStageName()

export function isAuthStage(stage) {
  if (stage !== undefined) {
    const prefix = stage.id.substr(0, 4)
    return prefix === 'auth'
  }
  return false
}

const stageIssued = stage => stages =>
  stages.find(stageItem => stageItem.id === stage) !== undefined
export const isPass2Issued = stageIssued('pass2')
export const isPass2NoIssued = stages => !isPass2Issued(stages)

export function passStageCount(item) {
  return item.workflow.stages.reduce(
    (acum, stage) => (isPassStage(stage) ? acum + 1 : acum),
    0
  )
}

/**
 * @usage isNextStage(store)('auth1')(requestData)
 */
export const isNextStage = store => stageName => requestData => {
  const prefix = 'workflows/willNextStage'
  const isStageNextFor = store.getters[`${prefix}${capitalized(stageName)}`]
  return isStageNextFor(requestData)
}

export const statusOfStage = stageName => onTime =>
  onTime ? `to_${stageName}` : `to_${stageName}_due`

export function isPassStage(stage) {
  if (stage !== undefined) {
    const prefix = stage.id.substr(0, 4)
    return prefix === 'pass'
  }
  return false
}

export function stageRoleId(stage) {
  return stage.role
}

// @todo Compare the effective time when the workflow
// returns to a any previous stage
export function currentStageOf(stages) {
  const copy = [...stages]
  copy.sort((a, b) => a.position - b.position)
  return copy.slice(-1)[0]
}

// @todo Compare the effective time when the workflow
// returns to a any previous stage
export function previousStageOfCurrent(stages) {
  const copy = [...stages]
  copy.sort((a, b) => a.position - b.position)
  return copy.slice(-2)[0]
}

// For workflow model //

export function isCheckinById(id) {
  return id === 'checkin'
}

export function isNoShowStage(stage) {
  return isCheckinStage(stage) && stage.no_show
}

export function isCanceledStage(stage) {
  return isCheckinStage(stage) && stage.canceled
}

export const stageInStages = isStage => stages =>
  stages.find(stage => isStage(stage))

export const auth1Stage = stageInStages(isAuth1Stage)
export const auth2Stage = stageInStages(isAuth2Stage)
export const auth3Stage = stageInStages(isAuth3Stage)

export const hasAuth1Stage = stages => auth1Stage(stages) !== undefined
export const hasAuth2Stage = stages => auth2Stage(stages) !== undefined
export const hasAuth3Stage = stages => auth3Stage(stages) !== undefined

export const isAuth1Approved = stages => isStageApproved(auth1Stage(stages))
export const isAuth2Approved = stages => isStageApproved(auth2Stage(stages))
export const isAuth3Approved = stages => isStageApproved(auth3Stage(stages))
