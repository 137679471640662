import { isAuth3Stage } from './stagesControl'
import {
  isOnExpectedTimeToAuth3,
  isOnLateTimeToAuth3,
  isWhithinTimeToAuth3
} from '@/components/requests/auths/auths3TimeControl'
import {
  STAGE_NOT_ON_ALERT,
  STAGE_ON_DUE,
  STAGE_ON_EARLIER,
  STAGE_ON_LATE
} from '@/components/requests/status/stageOnAlert'

export function resolveTimeToAuth3Status(period, next) {
  let status = ''
  if (isAuth3Stage(next)) {
    if (isWhithinTimeToAuth3(period)) {
      if (isOnExpectedTimeToAuth3(period)) {
        status = STAGE_NOT_ON_ALERT
      } else {
        status = isOnLateTimeToAuth3(period) ? STAGE_ON_LATE : STAGE_ON_EARLIER
      }
    } else {
      status = STAGE_ON_DUE
    }
  }
  return { matched: status && status.length > 0, value: status }
}
