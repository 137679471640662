import { extractDate } from '@/micro/time/date'
import { addDays, subDays } from 'date-fns'
// import { getJsonItem } from '@/store/nativeStorage'
import { addNewAccessRequest } from './requestsApi/fbWriteRequestsApi'
import { addNewAccessStage } from './requestsApi/fbWriteStagesApi'
import { resolvedNextStageIdById } from '@/components/requests/workflow/conditions/readConditions'
import {
  stageId,
  currentStageOf
} from '@/components/requests/workflow/stagesControl'
import { stagesInRequest } from '@/components/requests/reqsControl'
import { alertLevelByTime } from '@/components/requests/workflow/nextControl'
import { periodIn } from '@/components/requests/reqsControl'
import { resourceInRequest } from '@/components/requests/reqsControl'

export const namespaced = true

// const item = getJsonItem('requests')
const requests = { request: [], auth: [] }
// item && item.requests ? item.requests : { active: [], past: [] }

const Input = {
  init: () => {
    const initInputRequest = {
      site: null,
      date: null,
      dates: [],
      schedules: [],
      fromTime: null,
      toTime: null,
      fromDateTime: null,
      toDateTime: null,
      duration: null,
      motivation: null,
      terms: false,
      party: {
        group: 'employee',
        attrs: null
      }
    }
    const initInputPartyAttrs = {
      alias: null,
      email: null,
      code: '+52',
      phone: null,
      people: 1
    }
    const initInput = initInputRequest
    initInput.party.attrs = initInputPartyAttrs
    return initInput
  },

  initValid: () => {
    return {
      party: false,
      site: false,
      date: false,
      time: false,
      motivation: false,
      terms: false
    }
  },

  reset: state => {
    state.input = null
    state.input = { ...Input.init() }
    state.valid = null
    state.valid = { ...Input.initValid() }
  }
}

export const state = {
  input: { ...Input.init() },
  valid: { ...Input.initValid() },
  requestStep: 1,
  requests,
  loading: false,
  newRequestSent: false,
  newRequestSending: false,
  snackbar: {
    ack: false,
    msg: ''
  }
}

/**
 * Getters
 */
export const getters = {
  isNewRequestSent(state) {
    return state.newRequestSent
  },
  isNewRequestSending(state) {
    return state.newRequestSending
  },
  snackbarSendingState(state) {
    return state.snackbar
  },
  requestsByType(state) {
    return type => state.requests[type]
  },

  currentInput(state) {
    return state.input
  },

  currentStep(state) {
    return state.requestStep
  },

  draft(state) {
    return state.input
  },

  party(state) {
    return state.input.party.attrs
  },

  groupName(state) {
    return state.input.party.group
  },

  isValidRequest(state) {
    return Object.values(state.valid).reduce(
      (previous, current) => previous && current,
      true
    )
  },

  isValidSection(state) {
    return section => state.valid[section]
  },

  areRequests(state) {
    if (state.requests) {
      if (state.requests.request) {
        return state.requests.request.length > 0
      } else if (state.requests.auth) {
        return state.requests.request.length > 0
      }
    }
    return false
  }
}

/**
 * Sync mutations
 */
export const mutations = {
  SET_VALID_REQUEST(state, { section, value }) {
    state.valid[section] = value
  },
  SAVE_DRAFT(state, { key, value }) {
    state.input[key] = value
  },
  SAVE_PARTY_DRAFT(state, { key, value }) {
    state.input.party.attrs[key] = value
  },
  SET_GROUP(state, name) {
    state.input.party.group = name
  },
  UPDATE_REQUESTS(state, { type, requests }) {
    state.requests[type] = requests
  },
  SET_STEP(state, step) {
    state.requestStep = step
  },
  INC_STEP(state) {
    state.requestStep = state.requestStep + 1
  },
  DEC_STEP(state) {
    state.requestStep = state.requestStep - 1
  },
  SET_LOADING(state) {
    state.loading = true
  },
  SET_LOADED(state) {
    state.loading = false
  },
  ADJUST_DATE(state, newDate) {
    state.input.date = newDate
  },
  SET_FROM_DATE_TIME(state, dateTime) {
    state.input.fromDateTime = dateTime
  },
  SET_TO_DATE_TIME(state, dateTime) {
    state.input.toDateTime = dateTime
  },
  SET_API_ACK(state, msg) {
    state.snackbar.ack = true
    state.snackbar.msg = msg
  },
  UNSET_API_ACK(state) {
    state.snackbar.ack = false
    state.snackbar.msg = ''
  },
  SET_NEW_REQUEST_SENT(state) {
    state.newRequestSent = true
    state.newRequestSending = false
  },
  SET_OFF_NEW_REQUEST_SENT(state) {
    state.newRequestSent = false
    state.newRequestSending = false
  },
  SET_NEW_REQUEST_SENDING(state) {
    state.newRequestSending = true
  }
}

/**
 * Async actions
 */
export const actions = {
  setFromDateTime({ commit }, dateTime) {
    commit('SET_FROM_DATE_TIME', dateTime)
  },
  setToDateTime({ commit }, dateTime) {
    commit('SET_TO_DATE_TIME', dateTime)
  },
  adjustDate({ commit }, { date, offsetInDays }) {
    let newDate
    if (offsetInDays < 0) {
      newDate = subDays(newDate(date), Math.abs(offsetInDays))
    } else {
      newDate = addDays(newDate(date), Math.abs(offsetInDays))
    }

    commit('ADJUST_DATE', extractDate(newDate))
  },
  saveDraft({ commit }, { key, value }) {
    return commit('SAVE_DRAFT', { key, value })
  },

  saveParty({ commit }, { key, value }) {
    return commit('SAVE_PARTY_DRAFT', { key, value })
  },

  selectGroup({ commit }, name) {
    return commit('SET_GROUP', name)
  },

  updateValidSection({ commit }, { section, value }) {
    commit('SET_VALID_REQUEST', { section, value })
  },

  async sendRequestToOrg({ getters, rootGetters }) {
    const rs = rootGetters['user/relationship']
    const ou = rootGetters['user/ou']
    const area = rootGetters['user/area']
    const areaName = rootGetters['user/areaName']
    const uid = rootGetters['user/uid']
    const input = getters.currentInput
    const name = rootGetters['user/name']
    const info = input.party.attrs.email ? input.party.attrs : null
    const party = { name, area, aname: areaName, l: rs, o: ou, info }
    await addNewAccessRequest(uid, input, party, rootGetters)
    Input.reset(state)
  },

  async sendStage({ dispatch }, { item, action, comment }) {
    const customStageAttrs = {}
    const area = 'any'
    return await dispatch('postNextStage', {
      area,
      item,
      action,
      comment,
      customStageAttrs
    })
  },

  async sendCheckStage({ dispatch }, { item, action, comment }) {
    const customStageAttrs = { no_show: false, canceled: false }
    const area = 'any'
    return await dispatch('postNextStage', {
      area,
      item,
      action,
      comment,
      customStageAttrs
    })
  },

  async postNextStage(
    { rootGetters },
    { area, item, action, comment, customStageAttrs }
  ) {
    // Setup the org
    const relationship = rootGetters['user/relationship']
    const ou = rootGetters['user/ou']
    const uid = rootGetters['user/uid']
    const name = rootGetters['user/name']

    // Current stage. i.e.: auth1
    const currentStageDescn = currentStageOf(stagesInRequest(item))
    const currentStageId = stageId(currentStageDescn)

    // Next stage to add, i.e.: auth2
    const resolvedNextId = await resolvedNextStageIdById(
      currentStageId,
      item,
      rootGetters
    )
    const stageDescriptionById = rootGetters['workflows/stageDescriptionById']
    const resolvedNextDescn = stageDescriptionById(resolvedNextId, item)
    const auth = resolvedNextDescn.type === 'auth'
    const status = action === 'approve' ? 'approved' : 'rejected'
    const alert = alertLevelByTime(
      resolvedNextDescn,
      periodIn(resourceInRequest(item))
    )
    const { position, id, type, role, description } = resolvedNextDescn
    const stage = {
      position,
      id,
      type,
      role,
      next: resolvedNextDescn.next,
      description,
      auth,
      status,
      alert,
      uid,
      name,
      comment,
      ...customStageAttrs
    }
    return await addNewAccessStage(stage, item, relationship, ou, area)
  },

  updateStep({ commit }, step) {
    commit('SET_STEP', step)
  },

  incStep({ commit }) {
    commit('INC_STEP')
  },

  decStep({ commit }) {
    commit('DEC_STEP')
  },

  resetStep({ commit }) {
    commit('SET_STEP', 1)
  },

  updateRequests({ commit }, { type, requests }) {
    commit('UPDATE_REQUESTS', { type, requests })
  },

  setLoading({ commit }) {
    commit('SET_LOADING')
  },

  setLoaded({ commit }) {
    commit('SET_LOADED')
  },

  newRequestSent({ commit }) {
    commit('SET_NEW_REQUEST_SENT')
  },

  setOffNewRequestSent({ commit }) {
    commit('SET_OFF_NEW_REQUEST_SENT')
  },

  newRequestSending({ commit }) {
    commit('SET_NEW_REQUEST_SENDING')
  },

  showSendingAck({ commit }, id) {
    const msg = id === 'ACCESS_REQUEST_SENT' ? 'Tu solicitud se ha enviado' : ''
    commit('SET_API_ACK', msg)
  },

  hideSendingAck({ commit }) {
    commit('UNSET_API_ACK')
  }
}
