import { isNowBeforeGracePeriodToCheckin } from '../pass/checkinTimeControl'
import { ON_PASS1_STAGE } from '../status/statusControl'
import { isPass1Stage } from '../workflow/stagesControl'

export function pass1Status(stage, period) {
  const isNowBeforeToleranceToCheckin = isNowBeforeGracePeriodToCheckin(
    period.start
  )
  return {
    match: isPass1Stage(stage) && isNowBeforeToleranceToCheckin,
    status: ON_PASS1_STAGE
  }
}
