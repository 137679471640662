import defaultState from '@/store/default/descriptions/workflows'
import {
  currentStageOf,
  previousStageOfCurrent
} from '@/components/requests/workflow/stagesControl'
import { statusOnWorkflowFor } from '@/components/requests/status/statusControl'
import { stageWorkflow } from '@/components/requests/workflow/workflowControl'

export const namespaced = true

export const state = {
  employee_one_access: null,
  employee_one_access_styles: null,
  employee_continuos_access: null,
  employee_continuos_access_styles: null,
  employee_files_access: null,
  employee_files_access_styles: null
}

/**
 * Getters
 */
export const getters = {
  // Ok
  stageDescriptionById(state) {
    return (stageId, item) => {
      const workflow = stageWorkflow(item)
      return state[workflow].find(descn => descn.id === stageId)
    }
  },

  // Ok
  outlinedNextStageIdById(state, getters) {
    return (stageId, request) => {
      const current = getters['stageDescriptionById'](stageId, request)
      return current.next
    }
  },

  // Ok
  outlinedNextStageDescriptionById(state, getters) {
    return (stageId, request) => {
      const nextId = getters['outlinedNextStageIdById'](stageId, request)
      return getters['stageDescriptionById'](nextId, request)
    }
  },

  // Ok
  currentStageDescription(state, getters) {
    return item => {
      const current = currentStageOf(item.workflow.stages)
      return getters['stageDescriptionById'](current.id, item)
    }
  },

  // Ok
  currentNextStageDescription(state, getters) {
    return item => {
      const current = currentStageOf(item.workflow.stages)
      return getters['stageDescriptionById'](current.next, item)
    }
  },

  // Ok
  currentNextStageId() {
    return item => {
      const current = currentStageOf(item.workflow.stages)
      return current.next
    }
  },

  // Ok
  isStageCurrent() {
    return (stage, item) => {
      const current = currentStageOf(item.workflow.stages)
      return stage.id === current.id
    }
  },

  // Ok Not yet deployed
  outlinedPreviousStageStatus(state, getters) {
    return (stage, item) => {
      const previous = getters.outlinedPreviousStageOf(stage, item)
      const statusOf = statusOnWorkflowFor(item)
      return statusOf(previous, item)
    }
  },

  // Ok Not yet deployed
  currentPreviousStageStatus(state, getters) {
    return (stage, item) => {
      const previous = getters.currentPreviousStageOf(item)
      const statusOf = statusOnWorkflowFor(item)
      return statusOf(previous, item)
    }
  },

  // Ok Not yet deployed
  outlinedPreviousStageOf(state) {
    return (stage, item) => {
      const workflow = stageWorkflow(item)
      const description = state[workflow].find(descn => descn.next === stage.id)
      return item.workflow.stages.find(current => current.id === description.id)
    }
  },

  // Ok
  currentPreviousStageOf() {
    return item => previousStageOfCurrent(item.workflow.stages)
  },

  // Ok
  isCurrentEndStage() {
    return item => {
      const current = currentStageOf(item.workflow.stages)
      return current.next === 'end'
    }
  },

  // Ok
  willNextStageAuth(state, getters) {
    return item => {
      const next = getters.currentNextStageDescription(item)
      return !!next && next.type === 'auth'
    }
  },

  // Ok
  willNextStageAuth1(state, getters) {
    return item => {
      const next = getters.currentNextStageDescription(item)
      return !!next && next.id === 'auth1'
    }
  },

  willNextStageAuth2(state, getters) {
    return item => {
      const next = getters.currentNextStageDescription(item)
      return !!next && next.id === 'auth2'
    }
  },

  willNextStageAuth3(state, getters) {
    return item => {
      const next = getters.currentNextStageDescription(item)
      return !!next && next.id === 'auth3'
    }
  },

  // Ok
  willNextStagePass1(state, getters) {
    return item => {
      const next = getters.currentNextStageDescription(item)
      return !!next && next.id === 'pass1'
    }
  },

  // Ok
  willNextStagePass2(state, getters) {
    return item => {
      const next = getters.currentNextStageDescription(item)
      return !!next && next.id === 'pass1'
    }
  },

  // Ok
  willNextStagePass(state, getters) {
    return item => {
      const next = getters.currentNextStageDescription(item)
      return !!next && next.type === 'pass'
    }
  },

  // Ok
  willNextStageCheckin(state, getters) {
    return item => {
      const next = getters.currentNextStageDescription(item)
      return !!next && next.id === 'checkin'
    }
  },

  // Ok
  willNextStageCheckout(state, getters) {
    return item => {
      const next = getters.currentNextStageDescription(item)
      return !!next && next.id === 'checkout'
    }
  },

  willNextStageEnd(state, getters) {
    return item => {
      const next = getters.currentNextStageDescription(item)
      return !!next && next.id === 'end'
    }
  },

  // Ok
  statusColorByStage(state, getters) {
    return (stage, status, item) => {
      const descn = getters.stageDescriptionById(stage.id, item)
      return getters.statusColor(status, descn, item)
    }
  },

  // Ok
  outlinedNextStatusColor(state, getters) {
    return (stage, status, item) => {
      const nextDescn = getters.outlinedNextStageDescriptionById(stage.id, item)
      return getters.statusColor(status, nextDescn, item)
    }
  },

  // Ok
  currentNextStatusColor(state, getters) {
    return (status, item) => {
      const nextDescn = getters.currentNextStageDescription(item)
      return getters.statusColor(status, nextDescn, item)
    }
  },

  // Ok
  statusColor(state) {
    return (status, stageDescription, item) => {
      const workflow = stageWorkflow(item)
      const key = `${workflow}_styles`
      const styles = state[key].find(
        style =>
          style.id === stageDescription.id &&
          style.type === stageDescription.type &&
          style.status === status
      )
      return styles ? styles.colors : ''
    }
  }
}

/**
 * Sync Mutations
 */
export const mutations = {
  INIT(state) {
    const {
      employee_one_access,
      employee_one_access_styles,
      employee_continuos_access,
      employee_files_access
    } = defaultState

    state.employee_one_access = employee_one_access
    state.employee_one_access_styles = employee_one_access_styles
    state.employee_continuos_access = employee_continuos_access
    state.employee_continuos_access_styles = employee_one_access_styles
    state.employee_files_access = employee_files_access
    state.employee_files_access_styles = employee_one_access_styles
  }
}

/**
 * Async actions
 */
export const actions = {
  setupWorkflows({ commit }) {
    commit('INIT')
  }
}
