/* eslint-disable no-unused-vars */
import { isDef, isObject } from '@/micro/utils'
/**
 * @todo Replace it with the use of Typescript. It adds more code.
 *
 * @param {object|any} {hh, mm}
 * @returns {boolean}
 */
const isDurationObject = v => {
  return isObject(v) && 'hh' in v && 'mm' in v
}

/**
 * @param {any}
 * @returns {boolean}
 */
const isTimeSet = v => {
  return isDef(v) && v !== 0 && !isNaN(new Date(v))
}

/**
 * @public and exported as durationOfText
 * @param {string|number|null|undefined} `time` 'hh:mm'|m|null|undefined
 * @returns {object} {hh, mm}
 */
const hhmm = time => {
  let hh = 0
  let mm = typeof time === 'number' ? time : 0
  if (time && typeof time === 'string') {
    const wt = time.split(':')
    hh = isDef(wt[0]) && !!wt[0] ? parseInt(wt[0]) : 0
    mm = isDef(wt[1]) && !!wt[1] ? parseInt(wt[1]) : 0
  }
  return { hh, mm }
}

/**
 * It checks if the duration was set
 *
 * @private
 * @param {number|null|undefined} `hh` Hours
 * @param {number|null|undefined} `mm` Minutes
 * @returns {boolean} True if 0, null or undefined
 */
const _isDurationSet = (duration, min) => {
  const { hh, mm } = isDurationObject(duration)
    ? { hh: duration.hh, mm: duration.mm }
    : { hh: duration, mm: min }
  return !((hh === 0 || !hh) && (mm === 0 || !mm))
}

/**
 * @public
 */
const isDurationSet = {
  /**
   * @param {object} Duration {hh, mm}
   */
  ofObject: ({ hh, mm }) => {
    return _isDurationSet({ hh, mm })
  },
  /**
   * @param {string} Duration hh:mm
   */
  ofText: duration => {
    return _isDurationSet(hhmm(duration))
  }
}

/**
 * If digit returns a string prefixed with 0
 *
 * @public
 * @param {number}
 * @returns {string}
 */
const ddFormat = dd => {
  return dd >= 0 && dd < 10 ? `0${dd}` : dd
}

/**
 * @param private
 * @param {number} `hh` The hours time
 * @param {number} `mm` The minutes time
 * @returns {number} The time in minutes
 */
const durationInMinutes = (hh, mm) => {
  let min = Math.abs(hh) * 60 + Math.abs(mm)
  if (hh < 0 || mm < 0) {
    min = -min
  }
  return min
}

const isDurationNegative = duration => {
  return duration.hh < 0 || duration.mm < 0
}

const isDurationTxtNegative = txt => {
  const hasNegative = txt.lastIndexOf('-') >= 0
  const duration = hhmm(txt)
  return hasNegative || isDurationNegative(duration)
}

/**
 * @param private
 * @param {number} min The time in minutes
 * @returns {Object} {hh, mm}
 */
const minInDuration = min => {
  return { hh: Math.floor(min / 60), mm: min % 60 }
}

/**
 * @param public
 */
const inMinutes = {
  ofVars: (hh, mm) => {
    return durationInMinutes(hh, mm)
  },
  ofObject: ({ hh, mm }) => {
    return durationInMinutes(hh, mm)
  },
  ofText: time => {
    const { hh, mm } = hhmm(time)
    return durationInMinutes(hh, mm)
  }
}

/**
 * @public
 * @param {object|number} The time to evaluate | hh = {hh, mm} | hh, mm
 * @param {number|undefined} The minutes | undefined
 * @returns {boolean} True if hh && mm are set to zero
 */
const isDurationZeros = (hhmm, mmu) => {
  let hh, mm
  if (typeof hhmm === 'object' && Object.keys(hhmm).length === 2) {
    hh = hhmm.hh
    mm = hhmm.mm
  } else {
    hh = hhmm
    mm = mmu
  }
  return hh === 0 && mm === 0
}

/**
 * @private
 */
const tplsMethods = {
  /**
   * @param {number} `hh` Hours
   * @param {number} `mm` Minutes
   * @param {hhZero: ['' | '-' | '--' | '00'], sign: ['-' | '']}
   * @returns Time formated as hh:mm | :mm | --:mm | 00:mm | 0:mm
   */
  'hh:mm': {
    applied: (hh, mm, { hhZero, sign }) => {
      // '', '-', '0' => h:mm; '--', '00' => hh:mm
      const fhh = hhZero.length === 2 ? ddFormat(hh) : hh
      hhZero = !hh && hh !== 0 ? '--' : hhZero
      const fmm = ddFormat(mm)
      let txt = `${hhZero}:--`
      if ((hh > 0 && mm > 0) || (hh > 0 && mm === 0)) {
        txt = `${fhh}:${fmm}`
      } else if (hh === 0) {
        txt = `${hhZero}:${fmm}`
      }
      return `${sign}${txt}`
    }
  },
  /**
   * @param {number} `hh` Hours
   * @param {number} `mm` Minutes
   * @returns {string} Time formatted as Hh Mm | -Hh Mm | Hh | Mm | seg
   */
  hmseg: {
    applied: (hh, mm, { sign }) => {
      let txt = !hh && hh !== 0 && !mm && mm !== 0 ? '' : '<1m'
      if (hh > 0 && mm > 0) {
        txt = `${hh}h ${mm}m`
      } else if (hh > 0 && mm === 0) {
        txt = `${hh}h`
      } else if (hh === 0 && mm > 0) {
        txt = `${mm}m`
      }
      return `${sign}${txt}`
    }
  }
}

/**
 * @param private and exported as formatted
 * @param {string} `tpl` The template requested
 * @param {hh, mm} `remaining` The remaining time
 * @param {object} {`hhZero`: ''|'-'|'--'|'00'}
 * @return {string} Time formatted with the tpl selected
 */
const formattedTime = (tpl, { hh, mm }, payload = {}) => {
  let sign = { sign: '' }
  if (hh < 0 || mm < 0) {
    sign = { sign: '-' }
    hh = Math.abs(hh)
    mm = Math.abs(mm)
  }
  return tplsMethods[tpl].applied(hh, mm, { ...payload, ...sign })
}

const now = _ => new Date().getTime()

/**
 * Import it as a module: import * as time or individuals for
 * tree shaking.
 */
export {
  isDurationNegative,
  isDurationTxtNegative,
  isDurationObject,
  hhmm as durationOfText,
  hhmm,
  isDurationSet,
  isDurationZeros,
  isTimeSet,
  formattedTime as formatted,
  inMinutes,
  minInDuration,
  now,
  ddFormat
}
