// Motion
/**
 * Alerts!
 * Purpose: For the semaphores. It is a qualified status
 * It helps to the color, text, icons and buttons indicators.
 */
export const STAGE_NOT_ON_ALERT = 'no_alert'
export const STAGE_ON_EARLIER = 'earlier'
export const STAGE_ON_LATE = 'late'
export const STAGE_ON_DUE = 'due'
export const isStageOnAlert = stage => stage.alert !== STAGE_NOT_ON_ALERT
export const isStageOnEarlier = stage => stage.alert !== STAGE_ON_EARLIER
export const isStageOnLate = stage => stage.alert !== STAGE_ON_LATE
export const isStageOnDue = stage => stage.alert !== STAGE_ON_DUE

/**
 * Purpose: It only indicates if the stage is pending or it also has an alert:
 *  pending or pending_alert
 */
const statusFor = pending => qualifier =>
  qualifier === 'regular' ? 'pending' : `${pending}_${qualifier}`
export const statusForPending = statusFor('pending')
export const StatusForPendingRegular = statusForPending('regular')
export const StatusForPendingAlert = statusForPending('alert')
