import {
  isCheckinStage,
  isCheckoutStage,
  isNoShowStage,
  isCanceledStage,
  isPass2Stage,
  isPass1Stage,
  authStageName,
  authStageType,
  checkinStageName,
  statusOfStage,
  isNextStage
} from '@/components/requests/workflow/stagesControl'
import {
  areAuthsApprovedToCheckin,
  areSomeAuthsRejected,
  currentAuths
} from '@/components/requests/auths/authsControl'
import { isWhithinTimeToAuth } from '@/components/requests/auths/authsTimeControl'
import { isWhithinTimeToAuth3 } from '@/components/requests/auths/auths3TimeControl'
import { nameOfCheckin } from '@/components/requests/pass/passControl'
import {
  isNowBeforeGracePeriodToCheckin,
  isWithinTimeToCheckin
} from '@/components/requests/pass/checkinTimeControl'

import {
  periodIn,
  resourceInRequest,
  scheduleStartInResource
} from '@/components/requests/reqsControl'

export { statusOf } from '@/components/requests/employees/oneAccessStatus'

/**
 * It returns the current workflow status.
 * It is the present moment.
 *
 * @param {Object} item The required item
 * @param {Object} isNextStage(store, stageName, requestData
 * @returns A string representing the status
 */
export function currentStatus(current, item, isNextStage) {
  // Rejected
  if (areSomeAuthsRejected(currentAuths(item))) return 'rejected'

  // const current = currentStageOf(item.workflow.stages)
  const resource = resourceInRequest(item)

  // Is the next stage auth3 ?
  const auth3 = authStageName(3)
  const isNextAuth3 = isNextStage(auth3)
  const onTimeToAuth3 = isWhithinTimeToAuth3(periodIn(resource))
  const statusOfAuth3 = statusOfStage(auth3)
  if (isNextAuth3(item) && onTimeToAuth3) return statusOfAuth3(onTimeToAuth3)

  // Is the next stage Auth1 or Auth2
  const isAnyAuthNext = isNextStage(authStageType())
  const onTimeToAuth = isWhithinTimeToAuth(periodIn(resource))
  const auth1 = authStageName(1)
  const auth2 = authStageName(2)
  const statusOfAuth = isNextStage(auth1)
    ? statusOfStage(auth1)
    : statusOfStage(auth2)

  if (isAnyAuthNext(item) && onTimeToAuth) return statusOfAuth(onTimeToAuth)

  // Pass 1
  const scheduleStart = scheduleStartInResource(resource)
  const isNowBeforeToleranceToCheckin = isNowBeforeGracePeriodToCheckin(
    scheduleStart
  )
  if (isPass1Stage(current) && isNowBeforeToleranceToCheckin) return 'pass1'

  // To checkin
  const areAuthsApproved = areAuthsApprovedToCheckin(item)
  const isNextCheckin = isNextStage(checkinStageName())
  const onTimeToCheckin = isWithinTimeToCheckin(scheduleStart)
  const statusOfCheckin = statusOfStage(nameOfCheckin())
  if (isNextCheckin(item) && areAuthsApproved && onTimeToCheckin)
    return statusOfCheckin(onTimeToCheckin)

  // No-show
  const isCheckinDue =
    isNextCheckin(item) && areAuthsApproved && !onTimeToCheckin
  const isNoShow = isCheckinDue || isNoShowStage(current)
  if (isNoShow) return 'no_show'

  // Canceled
  const isCurrentCheckin = isCheckinStage(current)
  const isCanceled = isCurrentCheckin && isCanceledStage(current)
  if (isCanceled) return 'canceled'

  // Pass 2
  if (isPass2Stage(current) && onTimeToCheckin) return 'pass2'

  // Checkin
  if (isCurrentCheckin) return 'checkin'

  // Checkout
  if (isCheckoutStage(current)) return 'checkout'

  // Expired
  if (!onTimeToCheckin) return 'expired'

  return 'error'
}

export function isCurrentStatusRejected(current, item, store) {
  return currentStatus(current, item, isNextStage(store)) === 'rejected'
}
