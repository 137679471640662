import { isAuth1Stage, isAuth2Stage } from './stagesControl'
import {
  isOnExpectedTimeToAuth,
  isOnLateTimeToAuth,
  isWhithinTimeToAuth
} from '@/components/requests/auths/authsTimeControl'
import {
  STAGE_NOT_ON_ALERT,
  STAGE_ON_DUE,
  STAGE_ON_EARLIER,
  STAGE_ON_LATE
} from '@/components/requests/status/stageOnAlert'

export function resolveTimeToAuthStatus(period, next) {
  let status = ''
  if (isAuth1Stage(next) || isAuth2Stage(next)) {
    if (isWhithinTimeToAuth(period)) {
      if (isOnExpectedTimeToAuth(period)) {
        status = STAGE_NOT_ON_ALERT
      } else {
        status = isOnLateTimeToAuth(period) ? STAGE_ON_LATE : STAGE_ON_EARLIER
      }
    } else {
      status = STAGE_ON_DUE
    }
  }
  return { matched: status.length > 0, value: status }
}
