import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { userPolicyCondition } from './conditionsControl'

export const projectOpen = async ({ request, rootGetters }) => {
  const snapshot = await userPolicyCondition(
    'open',
    'projects',
    request,
    rootGetters
  )
  if (snapshot.empty) {
    return false
  } else {
    return snapshot.docs.reduce((prev, curr) => {
      const now = firebase.firestore.Timestamp.now().valueOf()
      const start = curr.get('start_date').valueOf()
      const end = curr.get('end_date').valueOf()
      return prev && now >= start && now <= end
    }, true)
  }
}
