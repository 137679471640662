import { isCheckinStage } from './stagesControl'
import {
  isBetweenAnyAndCheckin,
  isOnExpectedTimeToCheckin,
  isOnLateTimeToCheckin
} from '@/components/requests/pass/checkinTimeControl'
import {
  STAGE_NOT_ON_ALERT,
  STAGE_ON_DUE,
  STAGE_ON_EARLIER,
  STAGE_ON_LATE
} from '@/components/requests/status/stageOnAlert'

export function resolveTimeToCheckinStatus(period, next) {
  let status = ''
  if (isCheckinStage(next)) {
    if (
      isBetweenAnyAndCheckin(period.start) ||
      isOnExpectedTimeToCheckin(period)
    ) {
      if (isOnExpectedTimeToCheckin(period)) {
        status = STAGE_NOT_ON_ALERT
      } else {
        status = isOnLateTimeToCheckin(period)
          ? STAGE_ON_LATE
          : STAGE_ON_EARLIER
      }
    } else {
      status = STAGE_ON_DUE
    }
  }
  return { matched: status && status.length > 0, value: status }
}
