import goTo from 'vuetify/lib/services/goto'
export const scrollBehavior = (to, from, savedPosition) => {
  let scrollTo = 0

  if (to.hash) {
    scrollTo = to.hash
  } else if (savedPosition) {
    scrollTo = savedPosition.y
  }

  return goTo(scrollTo)
}

export default scrollBehavior
