import { setJsonItem } from '@/store/nativeStorage'

export function saveUserPlugin(store) {
  // Connect to the user.js store
  savePlugin(store, 'user')
}

export function saveResourcesPlugin(store) {
  // Connect to the resources.js store
  savePlugin(store, 'resources')
}

export function saveRequirementsPlugin(store) {
  // Connect to the requirements.js store
  savePlugin(store, 'requirements')
}

export function saveRequestsPlugin(store) {
  // Connect to the requests.js store
  savePlugin(store, 'requests')
}

function savePlugin(store, key) {
  store.subscribe((mutation, state) => {
    setJsonItem(key, state[key])
  })
}
