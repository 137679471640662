import {
  dangerDurationToAuth,
  invalidDurationToAuth,
  warningDurationToAuth
} from '@/cfg'
import { isBefore, isAfter, isWithinInterval, subMinutes } from 'date-fns/fp'
import { wasInFromStart } from '@/components/requests/reqsTimeControl'
import { isBetweenAnyAndCheckin } from '../pass/checkinTimeControl'

/**
 * @param {{start: Date, end: Date}} period
 * @returns boolean
 */
export function isWhithinTimeToAuth(period) {
  return (
    isOnExpectedTimeToAuth(period) ||
    isOnWarningTimeToAuth(period) ||
    isOnDangerTimeToAuth(period)
  )
}

/**
 * @param {{start: Date, end: Date}} period
 * @param {string | Date} effective
 * @returns boolean
 */
export function wasWithinTimeToAuth(period, effective) {
  return (
    wasOnExpectedTimeToAuth(period, effective) ||
    wasOnWarningTimeToAuth(period, effective) ||
    wasOnDangerTimeToAuth(period, effective)
  )
}

export function isOnLateTimeToAuth(period) {
  return (
    wasOnWarningTimeToAuth(period, new Date()) ||
    wasOnDangerTimeToAuth(period, new Date())
  )
}

export function wasOnLateTimeToAuth(period, effective) {
  return (
    wasOnWarningTimeToAuth(period, effective) ||
    wasOnDangerTimeToAuth(period, effective)
  )
}

/**
 * @param {{start: Date, end: Date}} period
 * @returns boolean
 */
export function isOnExpectedTimeToAuth(period) {
  return wasOnExpectedTimeToAuth(period, new Date())
}

/**
 * @param {{start: Date, end: Date}} period
 * @param {string | Date} effective
 * @returns boolean
 */
export function wasOnExpectedTimeToAuth(period, effective) {
  return wasInFromStart(expectedSegmentToAuth, period, effective)
}

/**
 * @param {{start: Date, end: Date}} period
 * @returns boolean
 */
export function isOnWarningTimeToAuth(period) {
  return wasOnWarningTimeToAuth(period, new Date())
}

/**
 * @param {{start: Date, end: Date}} period
 * @param {string | Date} effective
 * @returns boolean
 */
export function wasOnWarningTimeToAuth(period, effective) {
  return wasInFromStart(warningSegmentToAuth, period, effective)
}

/**
 * @param {{start: Date, end: Date}} period
 * @return boolean
 */
export function isOnDangerTimeToAuth(period) {
  return wasOnDangerTimeToAuth(period, new Date())
}

/**
 * @param {{start: Date, end: Date}} period
 * @param {string | Date} effective
 * @returns boolean
 */
export function wasOnDangerTimeToAuth(period, effective) {
  return wasInFromStart(dangerSegmentToAuth, period, effective)
}

/**
 * @return boolean
 */
export function isOnInvalidTimeToAuth(period) {
  return wasOnInvalidTimeToAuth(period, new Date())
}

export function isValidTimeToAuth(period) {
  return (
    !wasOnInvalidTimeToAuth(period, new Date()) &&
    (isBetweenAnyAndCheckin(period.start) || isWhithinTimeToAuth(period))
  )
}

/**
 * @param {{start: Date, end: Date}} period
 * @param {string | Date} effective
 * @returns boolean
 */
export function wasOnInvalidTimeToAuth(period, effective) {
  return wasInFromStart(invalidTimeToAuth, period, effective)
}

/**
 * @param {Date} scheduleStart
 * @returns curriedFn1
 */
function expectedSegmentToAuth(scheduleStart) {
  const unexpectedPeriod =
    invalidDurationToAuth() + dangerDurationToAuth() + warningDurationToAuth()
  const rightEndpoint = subMinutes(unexpectedPeriod)
  const startLessUnexpectedPeriod = rightEndpoint(scheduleStart)
  return isBefore(startLessUnexpectedPeriod)
}

/**
 * @param {Date} scheduleStart
 * @returns boolean
 */
function warningSegmentToAuth(scheduleStart) {
  const fromOverToWarning = subMinutes(
    invalidDurationToAuth() + dangerDurationToAuth() + warningDurationToAuth()
  )
  const start = fromOverToWarning(scheduleStart)

  const fromOverToDanger = subMinutes(
    invalidDurationToAuth() + dangerDurationToAuth()
  )
  const end = fromOverToDanger(scheduleStart)
  return isWithinInterval({ start, end })
}

/**
 * @param {Date} scheduleStart
 * @returns boolean
 */
function dangerSegmentToAuth(scheduleStart) {
  const fromOverToDanger = subMinutes(
    invalidDurationToAuth() + dangerDurationToAuth()
  )
  const start = fromOverToDanger(scheduleStart)
  const scheduleLessTimeOver = subMinutes(invalidDurationToAuth())
  const end = scheduleLessTimeOver(scheduleStart)
  return isWithinInterval({ start, end })
}

/**
 * @param {Date} scheduleStart
 * @returns boolean
 */
function invalidTimeToAuth(scheduleStart) {
  const scheduleLessTimeOver = subMinutes(invalidDurationToAuth())
  return isAfter(scheduleLessTimeOver(scheduleStart))
}
