import { isCheckoutStage } from './stagesControl'
import {
  isOnExpectedTimeToCheckout,
  isOnLateTimeToCheckout,
  isWithinTimeToCheckout
} from '@/components/requests/pass/checkoutTimeControl'
import {
  STAGE_NOT_ON_ALERT,
  STAGE_ON_DUE,
  STAGE_ON_EARLIER,
  STAGE_ON_LATE
} from '@/components/requests/status/stageOnAlert'

export function resolveTimeToCheckoutStatus(period, next) {
  let status = ''
  if (isCheckoutStage(next)) {
    if (isWithinTimeToCheckout(period) || isOnExpectedTimeToCheckout(period)) {
      if (isOnExpectedTimeToCheckout(period)) {
        status = STAGE_NOT_ON_ALERT
      } else {
        status = isOnLateTimeToCheckout(period)
          ? STAGE_ON_LATE
          : STAGE_ON_EARLIER
      }
    } else {
      status = STAGE_ON_DUE
    }
  }
  return { matched: status && status.length > 0, value: status }
}
