// Index in router (in working progress)
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'
// import validPath from '@/router/validPath'
import scrollBehavior from '@/router/scrollBehavior'
import { getFirebaseUser } from '@/firebaseInit'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.auth)
  if (requiresAuth && !(await getFirebaseUser())) {
    next('login')
  } else {
    // const user = await getFirebaseUser()
    // const token = await user.getIdTokenResult()
    // console.log('user', user)
    // console.log('token', token)
    // console.log('claims', token.claims)
    next()
  }
  // if (requiresAuth) {
  //   const user = await getFirebaseUser()
  //   if (!user) {
  //     // Go to login, the user is not sigend in
  //     next('login')
  //   } else {
  //     // Go to the asked route, the user is logged in
  //     next()
  //   }
  // } else {
  //   // The route doesn't require auth
  //   next()
  // }
  // if (requiresAuth) {
  //   const user = await getFirebaseUser()
  //   if (!user) {
  //     next('login')
  //   } else if (user && !user.isAnonymous) {
  //     console.log('user', user)
  //     firebaseAuth.currentUser.getIdTokenResult().then(function({ claims }) {
  //       const isAppInit = router.app.$store.getters['user/isAppInit']
  //       const areRolesInit = !!claims.r
  //       const isG = areRolesInit
  //         ? claims.r.find(r => r === 'g') !== undefined
  //         : false
  //       console.log('isAppInit', isAppInit)
  //       console.log('areRolesInit', areRolesInit)
  //       console.log('isG', isG)
  //       if (isG) {
  //         // Check
  //         if (to.name !== 'PassCode') {
  //           console.log('in the assertion', to)
  //           if (to.params.id) {
  //             console.log('trying to go to check', to)
  //             return next({
  //               path: `/check/${to.params.id}`
  //             })
  //           } else {
  //             console.log('go ahead in isG')
  //             return next()
  //           }
  //         } else {
  //           return next()
  //         }
  //       } else {
  //         console.log('else...')
  //         // Manange the rest of the roles
  //         const areSectionsCompleted =
  //           (claims.s && claims.s['a'] && claims.s['b']) ||
  //           router.app.$store.getters['user/areSectionsRequiredCompleted']
  //         const isSectionCompleted =
  //           router.app.$store.getters['user/isSectionCompleted']
  //         const isProfileCompleted = isSectionCompleted('profile')
  //         const areDocsCompleted = isSectionCompleted('documents')
  //         const dispatch = router.app.$store.dispatch
  //         const enableRoute = 'routes/enableRoute'
  //         if (isProfileCompleted) {
  //           dispatch(enableRoute, 'Profile')
  //           dispatch(enableRoute, 'Documents')
  //         }
  //         if (areSectionsCompleted) {
  //           dispatch(enableRoute, 'Requests')
  //         }
  //         if (!isProfileCompleted) {
  //           console.log('canAccess && !isProfileCompleted', to.path)
  //           if (to.path !== '/profile') {
  //             return next({
  //               path: '/profile'
  //             })
  //           }
  //           console.log('go ahead in profile')
  //         } else if (!areDocsCompleted) {
  //           console.log('canAccess && !areDocsCompleted', to.path)
  //           if (to.path !== '/documents') {
  //             return next({
  //               path: '/documents'
  //             })
  //           }
  //         } else {
  //           console.log('go by else')
  //           next()
  //         }
  //       }
  //     })
  //   }
  // } else {
  //   // If this not requires auth, then go to the route
  //   //@todo if (!validPath(to)) ...
  //   console.log('go by the last next')
  //   next()
  // }

  // firebase.auth().onAuthStateChanged(
  //   async authUser => {
  //     if (authUser && firebase.auth().currentUser && !authUser.isAnonymous) {
  //       // Is logged in
  //       firebase
  //         .auth()
  //         .currentUser.getIdTokenResult()
  //         .then(function({ claims }) {
  //           const isAppInit = router.app.$store.getters['user/isAppInit']
  //           const areRolesInit = !!claims.r
  //           const isG = areRolesInit
  //             ? claims.r.find(r => r === 'g') !== undefined
  //             : false
  //           if (!isAppInit && areRolesInit && !isG) {
  //             if (to.path !== '/loading') {
  //               return next({
  //                 path: '/loading'
  //               })
  //             }
  //           } else if (isG) {
  //             if (to.name !== 'PassCode') {
  //               if (to.params.id) {
  //                 return next({
  //                   path: `/check/${to.params.id}`
  //                 })
  //               } else {
  //                 return next(false)
  //               }
  //             }
  //           } else {
  //             const areSectionsCompleted =
  //               (claims.s && claims.s['a'] && claims.s['b']) ||
  //               router.app.$store.getters['user/areSectionsRequiredCompleted']
  //             const isSectionCompleted =
  //               router.app.$store.getters['user/isSectionCompleted']
  //             const isProfileCompleted = isSectionCompleted('profile')
  //             const areDocsCompleted = isSectionCompleted('documents')
  //             const dispatch = router.app.$store.dispatch
  //             const enableRoute = 'routes/enableRoute'
  //             if (isProfileCompleted) {
  //               dispatch(enableRoute, 'Profile')
  //               dispatch(enableRoute, 'Documents')
  //             }
  //             if (areSectionsCompleted) {
  //               dispatch(enableRoute, 'Requests')
  //             }
  //             if (!isProfileCompleted) {
  //               console.log('canAccess && !isProfileCompleted', to.path)
  //               if (to.path !== '/profile') {
  //                 return next({
  //                   path: '/profile'
  //                 })
  //               }
  //             } else if (!areDocsCompleted) {
  //               console.log('canAccess && !areDocsCompleted', to.path)
  //               if (to.path !== '/documents') {
  //                 return next({
  //                   path: '/documents'
  //                 })
  //               }
  //             } else {
  //               next()
  //             }
  //           }
  //         })
  //     } else {
  //       // Is not logged in
  //       if (to.matched.some(record => record.meta.auth) || !validPath) {
  //         // If try to go to a private route then send it to login
  //         next({
  //           path: '/login',
  //           query: {
  //             redirect: to.fullPath
  //           }
  //         })
  //       } else {
  //         next()
  //       }
  //     }
  //   },
  //   error => {
  //     console.log('routing', error)
  //   }
  // )
})

export default router
