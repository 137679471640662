import { isAuthStage } from '@/components/requests/workflow/stagesControl'

export const nameOfAuthsToAction = () => 'auth'

export function hasRequestPendingAuthsOf(stages, store, type) {
  return (
    areAllAuthsPendingOf(stages) || areSomeAuthsPendingOf(stages, store, type)
  )
}

const areAllAuthsPendingOf = stages => currentAuthsOf(stages).length === 0

const areSomeAuthsPendingOf = (stages, store, type) =>
  currentAuthsOf(stages).length < store.getters['authsWorkflow/authsNum'](type)

export function currentAuths(item) {
  return currentAuthsOf(item.workflow.stages)
}

export function currentAuthsOf(stages) {
  return stages.filter(stage => isAuthStage(stage))
}

export function areSomeAuthsRejected(authsStages) {
  return !authsStages.reduce((result, stage) => {
    return result && stage.status === 'approved'
  }, true)
}

export function areAllAuthsApproved(item) {
  return (
    isHeadAuthApproved(item.workflow.stages) &&
    isHeadSecurityAuthApproved(item.workflow.stages) &&
    isHealthAuthApproved(item.workflow.stages)
  )
}

export function areAuthsApprovedToCheckin(item) {
  return (
    isHeadAuthApproved(item.workflow.stages) &&
    isHeadSecurityAuthApproved(item.workflow.stages)
  )
}

export function isHeadAuthApproved(stages) {
  return (
    stages.find(stage => stage.role === 'head' && stage.auth === true) !==
    undefined
  )
}

export function isHeadSecurityAuthApproved(stages) {
  return (
    stages.find(
      stage => stage.role === 'head_security' && stage.auth === true
    ) !== undefined
  )
}

export function isHealthAuthApproved(stages) {
  return (
    stages.find(stage => stage.role === 'health' && stage.auth === true) !==
    undefined
  )
}

export function isHealthAuthRejected(stages) {
  return (
    stages.find(stage => stage.role === 'health' && stage.auth === false) !==
    undefined
  )
}
