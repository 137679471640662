import { conditionAssessment } from './conditionsControl'
import {
  endStageName,
  isStageIdEnd
} from '@/components/requests/workflow/stagesControl'

/**
 * Resolves the next stage id by the main or alternative paths.
 *
 * If there are no alternatives defined or no alternatives matched
 * it returns the main/success next path id. Else, it returns
 * the first alternative that fulfills all its conditions.
 * @param {string} stageId The current stage id
 * @param {Object} request The request data
 * @param {Vuex} rootGetters To access the workflow
 * @returns The string id
 */
export async function resolvedNextStageIdById(stageId, request, rootGetters) {
  const outlinedNextStageId = rootGetters['workflows/outlinedNextStageIdById']
  const nextStageId = outlinedNextStageId(stageId, request)
  try {
    if (isStageIdEnd(nextStageId)) {
      return await Promise.resolve(endStageName())
    } else {
      const outlinedNextStageDescn =
        rootGetters['workflows/outlinedNextStageDescriptionById']
      const nextStageDescn = outlinedNextStageDescn(stageId, request)
      const context = { stage: nextStageDescn, request, rootGetters }
      const noAlts = !nextStageDescn.alts || nextStageDescn.alts.length === 0
      return noAlts
        ? await Promise.resolve(nextStageId)
        : await resolvedNextPathId(nextStageDescn.alts, context)
    }
  } catch (error) {
    console.log('Can not resolve the next stage', error)
  }
}

async function resolvedNextPathId(alts, context) {
  const preparedEvals = alts.map(altEval(context))
  const resolvedEvals = await Promise.all(preparedEvals)
  return firstAltOrMainPath(resolvedEvals)
}

function altEval(context) {
  return async alt => {
    if (!alt.conditions || alt.conditions.length === 0) {
      return { matched: await Promise.resolve(false), nextId: alt.id }
    } else {
      return {
        matched: await areAllConditionsTrue(alt.conditions, context),
        nextId: alt.id
      }
    }
  }
}

async function areAllConditionsTrue(conditions, context) {
  const preparedEvals = conditions.map(con => conditionAssessment(con, context))
  const resolvedEvals = await Promise.all(preparedEvals)
  return resolvedEvals.reduce((p, c) => p && c, true)
}

function firstAltOrMainPath(resolvedAltEvals, nextStageDescn) {
  const nextIds = resolvedAltEvals.filter(ev => ev.matched).map(ev => ev.nextId)
  return nextIds.length > 0 ? nextIds[0] : nextStageDescn.id
}
