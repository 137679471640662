import { isWhithinTimeToAuth3 } from '../auths/auths3TimeControl'
import { periodIn, resourceInRequest, stagesInRequest } from '../reqsControl'
import {
  isPass1Stage,
  isPass2Stage,
  isPass2Issued
} from '@/components/requests/workflow/stagesControl'
import {
  isBetweenCreatedAndAndCheckin,
  isWithinTimeToCheckin
} from './checkinTimeControl'

export const nameOfCheckin = () => 'checkin'
/**
 * The code is active while
 *  - the user has the pass1 and he/she is on time
 *  - the user has the pass2 (he/she has been auth3) and he/she is on time
 */
export function isCodeActive(stage, item) {
  const resource = resourceInRequest(item)
  const hasPass2 = isPass2Issued(stagesInRequest(item))
  return (
    isPass1Active(stage, resource, item, hasPass2) ||
    canCheckinWithPass2(stage, resource, hasPass2)
  )
}

const isPass1Active = (stage, resource, request, isPass2Issued) => {
  return (
    isPass1Stage(stage) &&
    !isPass2Issued &&
    (isBetweenCreatedAndAndCheckin(request) ||
      isWithinTimeToCheckin(periodIn(resource)) ||
      isWhithinTimeToAuth3(periodIn(resource)))
  )
}

// @todo it is pending to finishi it with the auth3 pass with
// valid periods.
const canCheckinWithPass2 = (stage, resource, isPass2Issued) => {
  const isTimeToCheckinNow = isWithinTimeToCheckin(periodIn(resource))
  return isPass2Stage(stage) && isPass2Issued && isTimeToCheckinNow
}
