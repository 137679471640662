<template>
  <v-app>
    <!-- Drawer Profile -->
    <!-- <v-navigation-drawer absolute right temporary v-model="drawer">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon>mdi-account</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ diplayName }}</v-list-item-title>
            <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list dense rounded>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          @click="signOut"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->
    <!-- /Drawer Profile -->
    <!-- Breadcrumb -->
    <v-app-bar app :dark="isDark" dense v-if="isLoggedIn">
      <v-breadcrumbs v-if="!isSomeCheck" :items="routes" divider="">
        <template v-slot:item="{ item }">
          <v-btn
            ripple
            chips
            rounded
            x-small
            exact
            active-class="router-link-active"
            exact-active-class="btn-active"
            :disabled="item.disabled"
            :to="item.to"
            :class="activeStyleByRoute(item)"
            class="text-subtitle-2"
          >
            {{ item.text }}
          </v-btn>
        </template>
      </v-breadcrumbs>
      <v-spacer />
      <!-- <v-btn icon @click.stop="drawer = !drawer">
        <v-icon>mdi-account</v-icon>
      </v-btn> -->
      <v-btn icon @click="signOut">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <!-- /Breadcrumb -->
    <v-main :class="bgColor">
      <router-view />
      <ask-notification-auth :requiresPermission="requiresPermission" />
      <recaptcha :visible="visibleTerms" />
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { firebaseAuth } from '@/firebaseInit'
import AskNotificationAuth from '@/components/messaging/AskNotificationAuth.vue'
import Recaptcha from '@/components/security/Recaptcha.vue'

export default {
  components: {
    AskNotificationAuth,
    Recaptcha
  },
  name: 'App',
  data: () => ({
    drawer: false,
    group: null,
    items: [{ title: 'Salir', icon: 'mdi-logout', link: '/' }]
  }),
  computed: {
    ...mapState('user', ['pushEnabled']),
    ...mapGetters('user', [
      'user',
      'isLoggedIn',
      'isAppInit',
      'isSecurityAssistant'
    ]),
    visibleTerms() {
      return !this.isLoggedIn
    },
    ...mapGetters('routes', ['routes']),
    requiresPermission() {
      return !this.pushEnabled && !this.isSecurityAssistant
    },
    isDark() {
      return this.isLoggedIn && this.isSecurityAssistant
    },
    bgColor() {
      return this.isDark ? 'bg-dark' : ''
    },
    isSomeCheck() {
      // Checkin / Checkout
      return this.$route.name === 'PassCode'
    },
    diplayName() {
      return this.user ? this.user.displayName : ''
    },
    email() {
      return this.user ? this.user.email : ''
    },
    providerId() {
      return this.user ? this.user.providerData[0].providerId : ''
    }
  },
  watch: {
    group() {
      this.drawer = false
    },
    isAppInit(newVal) {
      if (newVal) {
        if (this.isSecurityAssistant) {
          if (this.$route.path != '/check') {
            this.$router.push({ name: 'PassCode' })
          }
        } else {
          if (this.$route.path != '/requests') {
            this.$router.push({ name: 'Requests' })
          }
        }
      }
    }
    // areSectionsRequiredCompleted: {
    //   deep: true,
    //   immediate: true,
    //   handler(completed) {
    //     console.log('areSectionsCompleted before')
    //     if (completed) {
    //       console.log('areSectionsCompleted on true')
    //       this.$router.push({ name: 'Requests' })
    //     }
    //   }
    // }
  },
  methods: {
    activeStyleByRoute(item) {
      return item.to === this.$route.name ? 'btn-active' : ''
    },
    ...mapActions('user', [
      'logout',
      'areSectionsRequiredCompleted',
      'setPushEnabled'
    ]),
    ...mapActions('workflows', ['setupWorkflows']),
    ...mapActions('assignments', ['setupAssignments']),
    ...mapActions('authsWorkflow', ['setupAuthsWorkflow']),
    ...mapActions('sites', ['setupSites']),
    ...mapActions('routes', ['setupRoutes']),
    async signOut(evt) {
      // It doesn't use Vue because of firebaseui
      // It nees a review to normalize it
      evt.stopPropagation()
      await firebaseAuth.signOut()
      this.logout()
      window.location.assign('/login')
      // This: this.$router.push({ name: 'Login' }), also doesn't work effectively
    }
  },
  created() {
    // When the user is sent to login it ensures to clean anything about auth
    if (firebaseAuth && this.$route.name && this.$route.name !== 'PassCode') {
      firebaseAuth.signOut().then(() => {
        this.logout()
      })
    }
    this.setupWorkflows()
    this.setupAssignments()
    this.setupAuthsWorkflow()
    this.setupSites()
    this.setupRoutes()
  },

  beforeDestroy() {
    // this.$store.dispatch('requests/unsubscribe')
  }
}
</script>

<style lang="scss">
.v-breadcrumbs__divider {
  padding: 0 3px !important;
}
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  // border-bottom: 1px solid red !important;
  -webkit-box-shadow: 0 1px 0 0 rgb(0 0 0 / 10%) !important;
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 10%) !important;
}
.bg-dark {
  background-color: #000;
}
.btn-active {
  background-color: #e1000f !important;
  color: #ffffff !important;
}
</style>
