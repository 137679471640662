import { setJsonItem } from '@/store/nativeStorageOperator'

export function saveUserOperatorPlugin(store) {
  // Connect to the store/userOperator.js store
  savePlugin(store, 'userOperator')
}

export function saveRequestsOperatorsPlugin(store) {
  // Connect to the store/requestsOperators.js store
  savePlugin(store, 'requestsOperators')
}

function savePlugin(store, key) {
  store.subscribe((mutation, state) => {
    setJsonItem(key, state[key])
  })
}
