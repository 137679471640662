import { isValidTimeToAuth } from '../auths/authsTimeControl'
import { isExpiredTimeToCheckout } from '../pass/checkoutTimeControl'
import { ON_EXPIRED_REQUEST } from '../status/statusControl'
import { isAnyPreviousAuthBeforeAccess } from '../workflow/stagesControl'

export function expiredStatus(stage, period) {
  const match =
    isAnyPreviousAuthBeforeAccess(stage) &&
    (!isValidTimeToAuth(period) || isExpiredTimeToCheckout(period))
  return {
    match,
    status: ON_EXPIRED_REQUEST
  }
}
