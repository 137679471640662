import { areAuthsApprovedToCheckin } from '../auths/authsControl'
import {
  isWithinTimeToCheckin,
  isAfterExtendedTimeToCheckin
} from '../pass/checkinTimeControl'
import { nameOfCheckin } from '../pass/passControl'
import {
  ON_CHECKIN_NO_SHOW,
  TO_CHECKIN_DUE_STAGE
} from '../status/statusControl'
import {
  checkinStageName,
  isNoShowStage,
  statusOfStage
} from '../workflow/stagesControl'

export function nextCheckinStatus(stage, request, period, isNextStage) {
  const isNextCheckin = isNextStage(checkinStageName())
  const areAuthsApproved = areAuthsApprovedToCheckin(request)
  const canCheckinByPath = isNextCheckin(request) && areAuthsApproved

  const isWithinTime = isWithinTimeToCheckin(period)
  const isAfterTime = isAfterExtendedTimeToCheckin(period)

  const isValidCheckin = canCheckinByPath && isWithinTime
  const isCheckinDue = canCheckinByPath && isAfterTime
  const isNoShow = isNoShowStage(stage)
  const match = isValidCheckin || isCheckinDue || isNoShow
  const statusOfCheckin = statusOfStage(nameOfCheckin())

  let status = ''
  if (isValidCheckin) {
    status = statusOfCheckin(isWithinTime)
  } else if (isCheckinDue) {
    status = TO_CHECKIN_DUE_STAGE
  } else if (isNoShow) {
    status = ON_CHECKIN_NO_SHOW
  }

  return { match, status }
}
