export function siteNameText(breakpoint) {
  let style = ''
  if (breakpoint.lgAndUp) {
    // Desktops & HR
    style = `font-weight-medium text-body-2 text-truncate`
  } else if (breakpoint.mdAndUp) {
    // Tablets Pro & laptops
    style = `font-weight-light text-body-2 text-truncate`
  } else if (breakpoint.smAndUp) {
    // Tablets
    style = `font-weight-medium text-body-2 text-truncate`
  } else {
    // Mobiles
    style = `font-weight-medium text-body-2 text-truncate`
  }
  return `${style} av-card-btn`
}

export function transformToVersales(words) {
  return words
    .map(w =>
      w && w.length > 2 ? w.charAt(0).toUpperCase() + w.slice(1) : w || ''
    )
    .join(' ')
}

export function truncate(txt, length = 16) {
  let res = txt
  if (txt && txt.length > length) {
    res = `${res.substring(0, length - 3)}...`
  }
  return res
}

export function isEmail(txt) {
  return txt.indexOf('@') !== -1
}

export function resolvedAreaName(val) {
  const i = val.indexOf('@')
  return i !== -1 ? val.substr(0, i) : val
}

import { capitalize as capitalizeText } from './utils'
export function capitalized(text) {
  return capitalizeText(text)
}

export function isString(text) {
  return typeof text === 'string'
}
