const storageKey = 'avo:k'
export function storageName() {
  return localStorage.getItem(storageKey)
}

export function setStorageName(uid, appId) {
  const key = `${appId}:${uid}`
  localStorage.setItem(storageKey, key)
}

export function deleteStorageName() {
  localStorage.removeItem(storageKey)
}
