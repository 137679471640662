import {
  sendSuccessfulAssessment,
  sendRejectedAssessment
} from './requestsOperatorsApi/fbWriteRequestsApi'
import { sendRequestForCall } from './requestsOperatorsApi/fbWriteCallRequest'
import {
  isPassWithinValidTime,
  isValidPeriodToNewPass
} from '@/components/requestsOperators/codeTimeCtl'
import { isDef, isNumeric } from '@/micro/utils'
import { CODE_LENGTH } from '@/cfg'
import { getJsonItem } from '@/store/nativeStorageOperator'
import { isString } from '@/micro/text'
import { parseISO } from 'date-fns/fp'
import { arrayFind, arrayHasOnly } from '@/micro/array'

const STORE_KEY = 'pass'
const Pass = {
  init: () => {
    return {
      [STORE_KEY]: {
        party: {
          name: null,
          code: '+52',
          phone: null,
          email: null,
          aname: null,
          aid: null,
          oid: null,
          rid: null
        },
        resource: {
          id: null,
          effectiveDate: null,
          code: null,
          pass: null,
          checkin: null,
          checkout: null,
          result: null,
          results: null,
          callRequest: null
        }
      }
    }
  },

  reset: state => {
    state[STORE_KEY] = null
    state[STORE_KEY] = { ...Pass.init()[STORE_KEY] }
  }
}

export const namespaced = true

const localRecord = getJsonItem('requestsOperators') || Pass.init()
const effective = localRecord.pass.resource.effectiveDate
const effectiveDate = isString(effective) ? parseISO(effective) : effective
localRecord.pass.resource.effectiveDate = effectiveDate

export const state = {
  pass: localRecord.pass,
  listeners: []
}

/**
 * Getters
 */
export const getters = {
  // Cases
  isSavedVaccineCase(state) {
    const results = state[STORE_KEY].resource.results || []
    const ref = ['covid19', 'vaccine']
    const res = arrayHasOnly(ref, results)
    return res
  },
  isSavedProbablyCovidCase(state) {
    const results = state[STORE_KEY].resource.results || []
    const isCovid19 = arrayFind('covid19', results)
    const isNotVaccinated = !arrayFind('vaccine', results)
    const res = isCovid19 && isNotVaccinated
    return res
  },
  isSavedContactCase(state) {
    const results = state[STORE_KEY].resource.results || []
    const ref = ['contact']
    const res = arrayHasOnly(ref, results)
    return res
  },
  isSavedTravelingCase(state) {
    const results = state[STORE_KEY].resource.results || []
    const ref = ['traveling']
    const res = arrayHasOnly(ref, results)
    return res
  },
  isSavedContactAndTravelingCase(state) {
    const results = state[STORE_KEY].resource.results || []
    const ref = ['contact', 'traveling']
    const res = arrayHasOnly(ref, results)
    return res
  },
  // End Cases
  isCallRequestSet(state) {
    return state[STORE_KEY].resource.callRequest
  },
  pass(state) {
    return state[STORE_KEY].resource
  },

  party(state) {
    return state[STORE_KEY].party
  },

  areaByName(state) {
    return name => state.areas.find(area => area.name === name)
  },

  areaById(state) {
    return id => state.areas.find(area => area.id === id)
  },

  orgName(state) {
    const orgs = [
      { id: 'a', name: 'Tesistán' },
      { id: 'b', name: 'Belenes' },
      { id: 'c', name: 'CEDIS' },
      { id: 'd', name: 'Natco' }
    ]
    const oid = state.pass.party.oid
    return oid ? orgs.find(o => o.id === oid).name : ''
  },

  hasValidPassCode(state) {
    const code = state[STORE_KEY].resource.code
    const effectiveDate = state[STORE_KEY].resource.effectiveDate
    return now =>
      isDef(code) &&
      isNumeric(code) &&
      `${code}`.length === CODE_LENGTH &&
      isDef(effectiveDate) &&
      isPassWithinValidTime(now, effectiveDate)
  },

  canGenerateNewAssessmentByTimeout(state) {
    return now => {
      const effectiveDate = state[STORE_KEY].resource.effectiveDate
      return isDef(effectiveDate) && isValidPeriodToNewPass(now, effectiveDate)
    }
  },

  hasAssessment(state) {
    return !!state[STORE_KEY].resource.result
  },

  wasAssessmentApproved(state) {
    return state[STORE_KEY].resource.result.toUpperCase() === 'APPROVED'
  },

  isValidRequest(state) {
    return Object.values(state.valid).reduce(
      (previous, current) => previous && current,
      true
    )
  }
}

/**
 * Sync mutations
 */
export const mutations = {
  SAVE_PARTY(state, party) {
    state[STORE_KEY].party = party
  },
  SAVE_RESOURCE(state, resource) {
    state[STORE_KEY].resource = resource
  },
  CLEAN_ALL(state) {
    Pass.reset(state)
  },
  CLEAN_PASS(state) {
    state[STORE_KEY].resource['code'] = null
    state[STORE_KEY].resource['effectiveDate'] = null
    state[STORE_KEY].resource['pass'] = null
    state[STORE_KEY].resource['checkin'] = null
    state[STORE_KEY].resource['checkout'] = null
  },
  CLEAN_ASSESSMENT(state) {
    state[STORE_KEY].resource['id'] = null
    state[STORE_KEY].resource['result'] = null
    state[STORE_KEY].resource['results'] = null
    state[STORE_KEY].resource['callRequest'] = null
  },
  UPDATE_PARTY(state, { key, value }) {
    state[STORE_KEY].party[key] = value
  },
  UPDATE_RESOURCE_ATTR(state, { key, value }) {
    state[STORE_KEY].resource[key] = value
  }
}

/**
 * Async actions
 */
export const actions = {
  async requestPass({ state, commit, rootGetters }, assessment) {
    const uid = rootGetters['userOperator/uid']
    const srcHost = `${window.location.protocol}//${window.location.host}`
    const toStoreMap = ({ id, result, pass, checkin, checkout }) => {
      return {
        id,
        effectiveDate: (pass && pass.issued.toDate()) || null,
        code: (pass && pass.code) || null,
        pass: (pass && pass.pass) || null,
        checkin: checkin || null,
        checkout: checkout || null,
        result
      }
    }
    const updateStore = response => {
      if (isDef(response)) {
        commit('SAVE_RESOURCE', toStoreMap(response))
        commit('SAVE_PARTY', response.party)
      }
    }
    const unsubscribe = await sendSuccessfulAssessment(
      uid,
      state[STORE_KEY].party,
      assessment,
      srcHost,
      updateStore
    )
    return unsubscribe
  },

  async informResult({ commit, state, rootGetters }, assessment) {
    const uid = rootGetters['userOperator/uid']
    const updateStore = response => {
      if (isDef(response)) {
        commit('UPDATE_RESOURCE_ATTR', { key: 'id', value: response.id })
        commit('UPDATE_RESOURCE_ATTR', {
          key: 'results',
          value: response.results
        })
      }
    }
    await sendRejectedAssessment(
      uid,
      state[STORE_KEY].party,
      assessment,
      updateStore
    )
    commit('CLEAN_PASS')
    commit('UPDATE_RESOURCE_ATTR', { key: 'result', value: 'REJECTED' })
    commit('UPDATE_RESOURCE_ATTR', { key: 'effectiveDate', value: new Date() })
  },

  async requestForCall({ commit, state, rootGetters }, assessment) {
    const uid = rootGetters['userOperator/uid']
    await sendRequestForCall(
      uid,
      state[STORE_KEY].party,
      state[STORE_KEY].resource.id,
      assessment
    )
    commit('UPDATE_RESOURCE_ATTR', { key: 'callRequest', value: true })
  },

  updateParty({ commit }, { key, value }) {
    commit('UPDATE_PARTY', { key, value })
  },

  cleanPass({ commit }) {
    commit('CLEAN_PASS')
  },

  cleanAssessment({ commit }) {
    commit('CLEAN_ASSESSMENT')
  },

  cleanAll({ commit }) {
    commit('CLEAN_ALL')
  }
}
