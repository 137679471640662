import {
  dangerDurationBeforeToCheckout,
  dangerLateDurationToCheckout,
  expectedDurationToCheckout,
  untilDangerLateDurationToCheckout,
  warningLateDurationToCheckout
} from '@/cfg'
import { anyToDate } from '@/micro/time/date'
import { isInGraceInterval } from '@/micro/time/dateCompare'
import {
  differenceInMinutes,
  isWithinInterval,
  addMinutes,
  subMinutes,
  isAfter
} from 'date-fns/fp'

import { paramToScheduleEnd, periodIn, resourceInRequest } from '../reqsControl'
import { wasIn, wasInFromEnd } from '../reqsTimeControl'

export function isWithinTimeToCheckout(period) {
  const { start, end } = period
  return wasWithinTimeToCheckout(start, end, new Date())
}

export function wasWithinTimeToCheckout(start, end, effective) {
  const isWithin = isWithinInterval({ start, end: endWithTolerance(end) })
  return isWithin(anyToDate(effective))
}

export function isExpiredTimeToCheckout(period) {
  return wasExpiredTimeToCheckout(period, new Date())
}

export function wasExpiredTimeToCheckout(period, effective) {
  const isAfterEndTolerance = isAfter(endWithTolerance(period.end))
  return isAfterEndTolerance(anyToDate(effective))
}

function endWithTolerance(end) {
  const addToleranceToEnd = addMinutes(untilDangerLateDurationToCheckout())
  return addToleranceToEnd(end)
}

/**
 * @returns boolean
 */
export function isInUnexpectedTimeToCheckoutFromPeriod(period) {
  const { start, end } = period
  return wasInUnexpectedTimeToCheckout(start, end, new Date())
}

/**
 * @returns boolean
 */
export function isInUnexpectedTimeToCheckoutFromRequest(request) {
  const now = new Date()
  const { start, end } = periodIn(resourceInRequest(request))
  return wasInUnexpectedTimeToCheckout(start, end, now)
}

/**
 * @param {{start: Date, end: Date}} period
 * @return boolean
 */
export function isAfterCheckout(period) {
  return wasAfterCheckout(period, new Date())
}

/**
 * @param {{start: Date, end: Date}} period
 * @param {string | Date} effective
 * @return boolean
 */
export function wasAfterCheckout(period, effective) {
  return afterCheckout(period.end)(effective)
}

/**
 * @param {Date} scheduleEnd
 * @return CurriedFn1
 */
function afterCheckout(scheduleEnd) {
  return isAfter(scheduleEnd)
}

/**
 * @returns boolean
 */
export function isCheckoutAheadSchedule(start, end) {
  return wasCheckoutAheadSchedule(start, end, new Date())
}

/**
 * @returns boolean
 */
export function wasCheckoutAheadSchedule(start, end, effective) {
  return (
    wasOnWarningBeforeToCheckout(start, end, effective) ||
    wasOnDangerBeforeToCheckout(start, end, effective)
  )
}

/**
 * @returns boolean
 */
export function wasInUnexpectedTimeToCheckout(start, end, effective) {
  return (
    wasOnWarningBeforeToCheckout(start, end, effective) ||
    wasOnDangerBeforeToCheckout(start, end, effective) ||
    wasOnDangerLateToCheckout(end, effective)
  )
}

/**
 * @param {Date} start
 * @param {Date} end
 * @return boolean
 */
export function isOnWarningBeforeToCheckout(start, end) {
  return wasOnWarningBeforeToCheckout(start, end, new Date())
}

/**
 * @param {Date} start
 * @param {Date} end
 * @param {string | Date} effective
 * @return boolean
 */
export function wasOnWarningBeforeToCheckout(start, end, effective) {
  const expectedDuration = expectedDurationToCheckout()
  const insideDuration = differenceInMinutes(start)(end)
  const dangerDuration = insideDuration * dangerDurationBeforeToCheckout()
  const warningDuration = insideDuration - expectedDuration - dangerDuration
  const startBefore = subMinutes(expectedDuration + warningDuration)
  const endBefore = subMinutes(expectedDuration)
  const isIn = isWithinInterval({
    start: startBefore(end),
    end: endBefore(end)
  })
  return isIn(anyToDate(effective))
}

/**
 * @param {{start: Date, end: Date}} period
 * @return boolean
 */
export function isOnDangerBeforeToCheckout(period) {
  const { start, end } = period
  return wasOnDangerBeforeToCheckout(start, end, new Date())
}

/**
 * @param {{[field]: string}} pass
 * @param {string | Date} effective
 * @return boolean
 */
export function wasOnDangerBeforeToCheckout(start, end, effective) {
  const insideDuration = differenceInMinutes(start)(end)
  const dangerDuration = insideDuration * dangerDurationBeforeToCheckout()
  const endUntilDanger = addMinutes(dangerDuration)
  const isIn = isWithinInterval({
    start,
    end: endUntilDanger(start)
  })
  return isIn(anyToDate(effective))
}

/**
 * @returns boolean
 */
export function isOnLateTimeToCheckout(period) {
  const now = new Date()
  return (
    wasOnWarningLateToCheckout(period, now) ||
    wasOnDangerLateToCheckout(period, now)
  )
}

/**
 * @returns boolean
 */
export function wasOnLateTimeToCheckout(period, effective) {
  return (
    wasOnWarningLateToCheckout(period, effective) ||
    wasOnDangerLateToCheckout(period, effective)
  )
}

/**
 * @param {{start: Date, end: Date}} period
 * @return boolean
 */
export function isOnExpectedTimeToCheckout(period) {
  return wasOnExpectedTimeToCheckout(period, new Date())
}

/**
 * @param {{start: Date, end: Date}} period
 * @param {string | Date} effective
 * @return boolean
 */
export function wasOnExpectedTimeToCheckout(period, effective) {
  return wasInFromEnd(expectedSegmentToCheckout, period, effective)
}

/**
 * @param {{start: Date, end: Date}} period
 * @return boolean
 */
export function isOnWarningLateToCheckout(period) {
  return wasOnWarningLateToCheckout(period, new Date())
}

/**
 * @param {{start: Date, end: Date}} period
 * @param {string | Date} effective
 * @return boolean
 */
export function wasOnWarningLateToCheckout(period, effective) {
  return wasInFromEnd(warningLateSegmentToCheckout, period, effective)
}

/**
 * @return boolean
 */
export function isOnDangerLateToCheckout(period) {
  return wasOnDangerLateToCheckout(period, new Date())
}

/**
 * @param {{start: Date, end: Date} | string | Date } endOrPeriod
 * @param {string | Date} effective
 * @return boolean
 */
export function wasOnDangerLateToCheckout(endOrPeriod, effective) {
  const end = paramToScheduleEnd(endOrPeriod)
  return wasIn(dangerLateSegmentToCheckout, end, effective)
}

/**
 * @param {Date} scheduleEnd
 * @return CurriedFn1
 */
function expectedSegmentToCheckout(scheduleEnd) {
  const toleranceInMin = expectedDurationToCheckout()
  return isInGraceInterval(scheduleEnd, toleranceInMin, toleranceInMin)
}

/**
 * @param {Date} scheduleEnd
 * @return CurriedF1
 */
function warningLateSegmentToCheckout(scheduleEnd) {
  const from = addMinutes(expectedDurationToCheckout())
  const to = addMinutes(warningLateDurationToCheckout())
  return isWithinInterval({ start: from(scheduleEnd), end: to(scheduleEnd) })
}

/**
 * @param {Date} scheducelEnd
 * @return CurriedF1
 */
function dangerLateSegmentToCheckout(scheduleEnd) {
  const untilWarning =
    expectedDurationToCheckout() + warningLateDurationToCheckout()
  const from = addMinutes(untilWarning)
  const to = addMinutes(untilWarning + dangerLateDurationToCheckout())
  return isWithinInterval({ start: from(scheduleEnd), end: to(scheduleEnd) })
}
