export default {
  employee_files_access: [
    {
      position: 0,
      id: 'init',
      type: 'init',
      role: 'requestor',
      next: 'auth1',
      description: 'Documento requerido'
    },
    {
      position: 1,
      id: 'auth1',
      type: 'auth',
      role: 'head',
      next: 'end',
      description: 'Revisión por el monitor'
    }
  ],

  employee_one_access: [
    {
      position: 0,
      id: 'init',
      type: 'init',
      role: 'requestor',
      next: 'auth1',
      alts: [
        {
          id: 'checkin',
          conditions: ['isEmployee', 'healthApprovalInDuration']
        },
        {
          id: 'checkin',
          conditions: ['isProvider', 'healthApprovalInDuration', 'projectOpen']
        },
        {
          id: 'checkin',
          conditions: ['isProvider', 'healthApprovalNotRequired', 'projectOpen']
        }
      ],
      description: 'Solicitud de acceso'
    },
    {
      position: 1,
      id: 'auth1',
      type: 'auth',
      role: 'head',
      next: 'auth2',
      description: 'Autorización del jefe inmediato'
    },
    {
      id: 'auth2',
      position: 2,
      type: 'auth',
      role: 'head_security',
      next: 'pass1',
      description: 'Autorización del jefe de seguridad'
    },
    {
      id: 'pass1',
      position: 3,
      type: 'pass',
      role: 'app',
      next: 'checkin',
      description: 'Pase para revisión médica'
    },
    {
      id: 'checkin',
      position: 4,
      type: 'check',
      role: 'door_security',
      next: 'auth3',
      description: 'Registro de ingreso'
    },
    {
      id: 'auth3',
      description: 'Aprobación de servicios médicos',
      position: 5,
      type: 'auth',
      role: 'health',
      next: 'pass2',
      alts: [
        {
          id: 'auth3',
          conditions: ['isEmployee', 'hasConsultantRole']
        },
        {
          id: 'checkout',
          conditions: [
            'isEmployee',
            'hasSecurityAssistantRole',
            'checkoutWithoutAuth3'
          ]
        },
        {
          id: 'catch',
          conditions: ['checkoutWithoutAuth3']
        }
      ]
    },
    {
      id: 'pass2',
      position: 6,
      type: 'pass',
      role: 'app',
      next: 'checkout',
      description: 'Pase con aprobación de salud'
    },
    {
      id: 'checkout',
      position: 7,
      type: 'check',
      role: 'door_security',
      next: 'end',
      description: 'Registro de salida'
    },
    {
      id: 'catch',
      position: -1,
      type: 'undefined',
      role: 'undefined',
      next: 'end',
      description: 'Solicitud no permitida'
    }
  ],

  employee_one_access_styles: [
    {
      id: 'init',
      type: 'init',
      status: 'pending',
      colors: {
        card: 'grey lighten-4',
        text: 'grey lighten-1',
        icon: 'grey lighten-1'
      }
    },
    {
      id: 'init',
      type: 'init',
      status: 'pending_alert',
      colors: {
        card: 'grey lighten-4',
        text: 'yellow accent-3',
        icon: 'yellow accent-3'
      }
    },
    {
      id: 'init',
      type: 'init',
      status: 'to_auth1',
      colors: {
        card: 'grey lighten-4',
        text: 'grey lighten-1',
        icon: 'grey lighten-1'
      }
    },
    {
      id: 'init',
      type: 'init',
      status: 'to_auth1_due',
      colors: {
        card: 'grey lighten-4',
        text: 'grey lighten-1',
        icon: 'grey lighten-4'
      }
    },
    {
      id: 'auth1',
      type: 'auth',
      status: 'pending',
      colors: {
        card: 'grey lighten-4',
        text: 'grey lighten-1',
        icon: 'grey lighten-1'
      }
    },
    {
      id: 'auth1',
      type: 'auth',
      status: 'pending_alert',
      colors: {
        card: 'yellow lighten-5',
        text: 'grey darken-4',
        icon: 'yellow accent-3'
      }
    },
    {
      id: 'auth1',
      type: 'auth',
      status: 'approved',
      colors: {
        card: 'lime lighten-5',
        text: 'light-green lighten-2',
        icon: 'light-green lighten-2'
      }
    },
    {
      id: 'auth1',
      type: 'auth',
      status: 'approved_alert',
      colors: {
        card: 'yellow lighten-5',
        text: 'grey darken-4',
        icon: 'yellow darken-1'
      }
    },
    {
      id: 'auth1',
      type: 'auth',
      status: 'rejected',
      colors: {
        card: 'red darken-3',
        text: 'red lighten-4',
        icon: 'red lighten-4'
      }
    },
    {
      id: 'auth1',
      type: 'auth',
      status: 'rejected_alert',
      colors: {
        card: 'red darken-3',
        text: 'red lighten-4',
        icon: 'red accent-3'
      }
    },
    {
      id: 'auth1',
      type: 'auth',
      status: 'to_auth2',
      colors: {
        card: 'grey lighten-4',
        text: 'grey lighten-1',
        icon: 'grey lighten-1'
      }
    },
    {
      id: 'auth1',
      type: 'auth',
      status: 'to_auth2_due',
      colors: {
        card: 'grey lighten-4',
        text: 'grey lighten-1',
        icon: 'grey lighten-4'
      }
    },
    {
      id: 'auth2',
      type: 'auth',
      status: 'pending',
      colors: {
        card: 'grey lighten-4',
        text: 'grey lighten-1',
        icon: 'grey lighten-1'
      }
    },
    {
      id: 'auth2',
      type: 'auth',
      status: 'pending_alert',
      colors: {
        card: 'yellow lighten-4',
        text: 'grey lighten-1',
        icon: 'yellow accent-3'
      }
    },
    {
      id: 'auth2',
      type: 'auth',
      status: 'approved',
      colors: {
        card: 'lime lighten-3',
        text: 'light-green darken-1',
        icon: 'light-green darken-1'
      }
    },
    {
      id: 'auth2',
      type: 'auth',
      status: 'approved_alert',
      colors: {
        card: 'yellow lighten-4',
        text: 'grey darken-4',
        icon: 'yellow darken-2'
      }
    },
    {
      id: 'auth2',
      type: 'auth',
      status: 'rejected',
      colors: {
        card: 'red darken-3',
        text: 'red lighten-4',
        icon: 'red lighten-4'
      }
    },
    {
      id: 'auth2',
      type: 'auth',
      status: 'rejected_alert',
      colors: {
        card: 'red darken-3',
        text: 'red lighten-4',
        icon: 'red lighten-4'
      }
    },
    {
      id: 'auth2',
      type: 'auth',
      status: 'to_auth3',
      colors: {
        card: 'grey lighten-4',
        text: 'grey lighten-1',
        icon: 'grey lighten-1'
      }
    },
    {
      id: 'auth2',
      type: 'auth',
      status: 'to_auth3_due',
      colors: {
        card: 'grey lighten-4',
        text: 'grey lighten-1',
        icon: 'grey lighten-4'
      }
    },
    {
      id: 'auth3',
      type: 'auth',
      status: 'pending',
      colors: {
        card: 'grey lighten-4',
        text: 'grey lighten-1',
        icon: 'grey lighten-1'
      }
    },
    {
      id: 'auth3',
      type: 'auth',
      status: 'pending_alert',
      colors: {
        card: 'amber lighten-5',
        text: 'grey darken-4',
        icon: 'yellow accent-3'
      }
    },
    {
      id: 'auth3',
      type: 'auth',
      status: 'approved',
      colors: {
        card: 'lime lighten-5',
        text: 'light-green darken-3',
        icon: 'light-green darken-3'
      }
    },
    {
      id: 'auth3',
      type: 'auth',
      status: 'approved_alert',
      colors: {
        card: 'amber lighten-5',
        text: 'grey darken-4',
        icon: 'yellow darken-3'
      }
    },
    {
      id: 'auth3',
      type: 'auth',
      status: 'rejected',
      colors: {
        card: 'red darken-3',
        text: 'red lighten-4',
        icon: 'red lighten-4'
      }
    },
    {
      id: 'auth3',
      type: 'auth',
      status: 'rejected_alert',
      colors: {
        card: 'red darken-3',
        text: 'red lighten-4',
        icon: 'red lighten-4'
      }
    },
    //
    {
      id: 'pass1',
      type: 'pass',
      status: 'pending',
      colors: {
        card: 'grey lighten-3',
        text: 'grey lighten-1',
        icon: 'grey lighten-1'
      }
    },
    {
      id: 'pass1',
      type: 'pass',
      status: 'pending_alert',
      colors: {
        card: 'grey lighten-3',
        text: 'grey lighten-1',
        icon: 'grey lighten-1'
      }
    },
    {
      id: 'pass1',
      type: 'pass',
      status: 'approved',
      colors: {
        card: 'grey darken-4',
        text: 'grey lighten-4',
        icon: 'grey lighten-4'
      }
    },
    {
      id: 'pass1',
      type: 'pass',
      status: 'approved_alert',
      colors: {
        card: 'grey lighten-4',
        text: 'grey darken-4',
        icon: 'grey darken-4'
      }
    },
    {
      id: 'pass1',
      type: 'pass',
      status: 'rejected',
      colors: {
        card: 'grey lighten-1',
        text: 'grey darken-4',
        icon: 'grey darken-4'
      }
    },
    {
      id: 'pass1',
      type: 'pass',
      status: 'rejected_alert',
      colors: {
        card: 'grey lighten-1',
        text: 'grey darken-4',
        icon: 'grey darken-4'
      }
    },
    // {
    //   id: 'pass1',
    //   type: 'pass',
    //   status: 'pass1',
    //   colors: {
    //     card: 'grey darken-4',
    //     text: 'grey lighten-4',
    //     icon: 'grey lighten-4'
    //   }
    // },
    //
    {
      id: 'pass1',
      type: 'pass',
      status: 'to_checkin',
      colors: {
        card: 'grey lighten-4',
        text: 'grey lighten-1',
        icon: 'grey lighten-1'
      }
    },
    {
      id: 'pass1',
      type: 'pass',
      status: 'to_checkin_due',
      colors: {
        card: 'grey lighten-4',
        text: 'grey lighten-1',
        icon: 'grey lighten-4'
      }
    },
    {
      id: 'pass1',
      type: 'pass',
      status: 'no_show',
      colors: {
        card: 'grey lighten-4',
        text: 'grey lighten-1',
        icon: 'pink accent-3'
      }
    },
    {
      id: 'checkin',
      type: 'check',
      status: 'pending',
      colors: {
        card: 'grey lighten-4',
        text: 'grey lighten-1',
        icon: 'grey lighten-1'
      }
    },
    {
      id: 'checkin',
      type: 'check',
      status: 'pending_alert',
      colors: {
        card: 'yellow accent-1',
        text: 'grey lighten-5',
        icon: 'purple lighten-4'
      }
    },
    {
      id: 'checkin',
      type: 'check',
      status: 'approved',
      colors: {
        card: 'brown lighten-5',
        text: 'purple darken-1',
        icon: 'purple darken-1'
      }
    },
    {
      id: 'checkin',
      type: 'check',
      status: 'checkin',
      colors: {
        card: 'brown lighten-5',
        text: 'purple darken-1',
        icon: 'grey lighten-1'
      }
    },
    {
      id: 'checkin',
      type: 'check',
      status: 'approved_alert',
      colors: {
        card: 'amber accent-1',
        text: 'purple darken-1',
        icon: 'purple darken-1'
      }
    },
    {
      id: 'checkin',
      type: 'check',
      status: 'rejected',
      colors: {
        card: 'red darken-3',
        text: 'red lighten-4',
        icon: 'red accent-3'
      }
    },
    {
      id: 'checkin',
      type: 'check',
      status: 'rejected_alert',
      colors: {
        card: 'red darken-3',
        text: 'red lighten-4',
        icon: 'red lighten-4'
      }
    },
    {
      id: 'pass2',
      type: 'pass',
      status: 'pending',
      colors: {
        card: 'grey lighten-4',
        text: 'grey lighten-1',
        icon: 'grey lighten-1'
      }
    },
    {
      id: 'pass2',
      type: 'pass',
      status: 'pending_alert',
      colors: {
        card: 'yellow accent-1',
        text: 'grey darken-4',
        icon: 'orange darken-4'
      }
    },
    {
      id: 'pass2',
      type: 'pass',
      status: 'approved',
      colors: {
        card: 'teal lighten-5',
        text: 'teal darken-4',
        icon: 'teal darken-4'
      }
    },
    {
      id: 'pass2',
      type: 'pass',
      status: 'approved_alert',
      colors: {
        card: 'amber accent-1',
        text: 'orange darken-4',
        icon: 'orange darken-4'
      }
    },
    {
      id: 'pass2',
      type: 'pass',
      status: 'rejected',
      colors: {
        card: 'grey lighten-4',
        text: 'grey darken-4',
        icon: 'grey darken-4'
      }
    },
    {
      id: 'pass2',
      type: 'pass',
      status: 'rejected_alert',
      colors: {
        card: 'grey lighten-4',
        text: 'grey darken-4',
        icon: 'grey darken-4'
      }
    },
    //
    {
      id: 'checkout',
      type: 'check',
      status: 'pending',
      colors: {
        card: 'grey lighten-4',
        icon: 'grey lighten-1',
        text: 'grey lighten-1'
      }
    },
    {
      id: 'checkout',
      type: 'check',
      status: 'pending_alert',
      colors: {
        card: 'yellow accent-1',
        icon: 'light-blue darken-3',
        text: 'grey darken-4'
      }
    },
    {
      id: 'checkout',
      type: 'check',
      status: 'approved',
      colors: {
        card: 'indigo darken-4',
        icon: 'light-blue darken-1',
        text: 'light-blue darken-1'
      }
    },
    {
      id: 'checkout',
      type: 'check',
      status: 'checkout',
      colors: {
        card: 'indigo darken-4',
        icon: 'light-blue darken-1',
        text: 'light-blue darken-1'
      }
    },
    {
      id: 'checkout',
      type: 'check',
      status: 'approved_alert',
      colors: {
        card: 'amber accent-1',
        icon: 'light-blue darken-3',
        text: 'light-blue darken-3'
      }
    },
    {
      id: 'checkout',
      type: 'check',
      status: 'rejected',
      colors: {
        card: 'red darken-3',
        text: 'red lighten-4',
        icon: 'red lighten-4'
      }
    },
    {
      id: 'checkout',
      type: 'check',
      status: 'rejected_alert',
      colors: {
        card: 'red darken-3',
        text: 'red lighten-4',
        icon: 'red lighten-4'
      }
    },
    {
      id: 'checkout',
      type: 'check',
      status: 'rejected_alert',
      colors: {
        card: 'red darken-3',
        icon: 'red lighten-4',
        text: 'red lighten-4'
      }
    }
  ],

  employee_continuos_access: [
    {
      id: 'auth3',
      position: 0,
      type: 'auth',
      role: 'health',
      next: 'init',
      description: 'Aprobación de servicios médicos'
    },
    {
      position: 1,
      id: 'init',
      type: 'init',
      role: 'requestor',
      next: 'auth1',
      description: 'Solicitud de acceso'
    },
    {
      position: 2,
      id: 'auth1',
      type: 'auth',
      role: 'head',
      next: 'auth2',
      description: 'Autorización del jefe inmediato'
    },
    {
      id: 'auth2',
      position: 3,
      type: 'auth',
      role: 'head_security',
      next: 'pass2',
      description: 'Autorización del jefe de seguridad'
    },
    {
      id: 'pass2',
      position: 4,
      type: 'pass',
      role: 'app',
      next: 'checkin',
      description: 'Aprobación de servicios médicos'
    },
    {
      id: 'checkin',
      position: 5,
      type: 'check',
      role: 'door_security',
      next: 'checkout',
      description: 'Registro de ingreso'
    },
    {
      id: 'checkout',
      position: 6,
      type: 'check',
      role: 'door_security',
      next: 'end',
      description: 'Registro de salida'
    }
  ]
}
