export function userInfo(user, claims) {
  return {
    displayName: user.displayName,
    email: user.email,
    uid: user.uid,
    providerData: user.providerData,
    appId: 'av',
    o: claims.o,
    r: claims.r || [],
    s: claims.s || [],
    l: claims.l || '',
    aid: claims.aid || '',
    aname: claims.aname || '',
    areas: claims.areas
  }
}

export function claimsInTokenResult(tokenResult) {
  return tokenResult.claims
}

export function uidInUser(user) {
  return user.uid
}

export function isNewUser(authResult) {
  return authResult.additionalUserInfo.isNewUser
}

export function isEmailVerified(claims) {
  return claims.email_verified
}

export function emailInClaims(claims) {
  return claims.email
}
