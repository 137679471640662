<template>
  <v-snackbar
    v-model="visible"
    :timeout="-1"
    absolute
    bottom
    light
    elevation="0"
    content-class="text-center font-weight-normal grey--text text--accent-1 recaptcha-tos"
  >
    Protegido por reCAPTCHA Enterprise. Ve la

    <a
      class="primary--text text-decoration-none"
      rel="noopener noreferrer"
      target="_blank"
      href="https://policies.google.com/privacy"
      >Política de Privacidad</a
    >
    y los
    <a
      class="primary--text text-decoration-none"
      rel="noopener noreferrer"
      target="_blank"
      href="https://policies.google.com/terms"
      >Términos del Servicio</a
    >
    aplicables.
  </v-snackbar>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss">
.recaptcha-tos {
  font-size: 12px !important;
}
.grecaptcha-badge {
  visibility: hidden;
}
</style>
