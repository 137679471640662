import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify)
// import colors from 'vuetify/lib/util/colors'
export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es'
  },
  theme: {
    themes: {
      light: {
        // primary: colors.grey.darken3,
        // secondary: colors.blue.lighten4,
        // accent: colors.indigo.base,
        identity: '#e1000f',
        primary: '#2196f3',
        secondary: '#607d8b',
        accent: '#e91e63',
        error: '#f44336',
        warning: '#ffc107',
        info: '#795548',
        success: '#8bc34a'
      }
    }
  }
})
