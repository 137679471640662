export default [
  {
    name: 'Profile',
    text: 'Roles',
    disabled: false,
    to: 'Profile',
    default: false
  },
  {
    name: 'Documents',
    text: 'Requisitos',
    disabled: false,
    to: 'Documents',
    default: false
  },
  {
    name: 'Requests',
    text: 'Accesos',
    disabled: true,
    to: 'Requests',
    default: true
  }
]
