export function arrayIntersection(a, b) {
  return a.filter(x => b.includes(x))
}

export function arrayFind(a, b) {
  return b.find(x => x === a) !== undefined
}

export function arrayHasOnly(ref, results) {
  return (
    arrayIntersection(ref, results).length === ref.length &&
    results.length === ref.length
  )
}
