// Auth1-2
export function warningDurationToAuth() {
  return CONSTS.WarningDurationToAuthInMins
}

export function dangerDurationToAuth() {
  return CONSTS.DangerDurationToAuthInMins
}

export function invalidDurationToAuth() {
  return CONSTS.InvalidDurationToAuthInMins
}

// Auth3
export function expectedDurationToAuth3() {
  return CONSTS.ExpectedDurationToAuth3InMins
}

export function warningDurationToAuth3() {
  return CONSTS.WarningDurationToAuth3InMins
}

export function dangerDurationToAuth3() {
  return CONSTS.DangerDurationToAuth3InMins
}

// Checkin
export function expectedDurationToCheckin() {
  return CONSTS.ExpectedDurationToCheckinInMins
}

export function warningEarlierDurationToCheckin() {
  return CONSTS.WarningEarlierDurationToCheckinInMins
}

export function warningLateDurationToCheckin() {
  return CONSTS.WarningLateDurationToCheckinInMins
}

export function dangerLateDurationToCheckin() {
  return CONSTS.DangerLateDurationToCheckinInMins
}

export function untilDangerLateDurationToCheckin() {
  return warningLateDurationToCheckin() + dangerLateDurationToCheckin()
}

// Checkout
export function expectedDurationToCheckout() {
  return CONSTS.ExpectedDurationToCheckoutInMins
}

export function dangerDurationBeforeToCheckout() {
  return CONSTS.DangerDurationBeforeToCheckoutInProportion
}

export function warningLateDurationToCheckout() {
  return CONSTS.WarningDurationAfterToCheckoutInMins
}

export function dangerLateDurationToCheckout() {
  return CONSTS.DangerDurationAfterToCheckoutInMins
}

export function untilDangerLateDurationToCheckout() {
  return (
    expectedDurationToCheckout() +
    warningLateDurationToCheckout() +
    dangerLateDurationToCheckout()
  )
}

const timeToResetPassCodeOnSuccess = 4500
const timeToResetPassCodeOnFailure = 8000
export const timeToResetPassCode = validCode =>
  validCode ? timeToResetPassCodeOnSuccess : timeToResetPassCodeOnFailure

export const ExpectedEvaluation = 'expected'
export const ExceptionEvaluation = 'exception'
export const EarlierQuality = 'earlier'
export const LateQuality = 'late'
export const DueQuality = 'due'
export const NoAppointment = 'no_appointment'
export const UnknowQuality = 'unknown'

export const isExpectedPass = ({ evaluation }) => {
  return evaluation === ExpectedEvaluation
}

export const isExceptionPass = ({ evaluation }) =>
  evaluation === ExceptionEvaluation

export const isEarlierPassException = ({ evaluation, quality }) =>
  evaluation === ExceptionEvaluation && quality === EarlierQuality

export const isLatePassException = ({ evaluation, quality }) =>
  evaluation === ExceptionEvaluation && quality === LateQuality

export function qualityByTime(isEarlier, isLate) {
  const evaluation =
    isEarlier || isLate ? ExceptionEvaluation : ExpectedEvaluation
  return {
    evaluation,
    quality: isEarlier ? EarlierQuality : LateQuality
  }
}

export const CODE_LENGTH = 6

const CONSTS = {
  // Auth1-2
  WarningDurationToAuthInMins: 10,
  DangerDurationToAuthInMins: 5,
  InvalidDurationToAuthInMins: 2,
  // Auth3
  ExpectedDurationToAuth3InMins: 10,
  WarningDurationToAuth3InMins: 5,
  DangerDurationToAuth3InMins: 2,
  // Checkin
  ExpectedDurationToCheckinInMins: 2,
  WarningEarlierDurationToCheckinInMins: 5,
  WarningLateDurationToCheckinInMins: 5,
  DangerLateDurationToCheckinInMins: 2,
  // Checkout
  ExpectedDurationToCheckoutInMins: 2,
  DangerDurationBeforeToCheckoutInProportion: 0.1,
  WarningDurationAfterToCheckoutInMins: 5,
  DangerDurationAfterToCheckoutInMins: 2
}
