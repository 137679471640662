import { nameOfAuthsToAction } from '../auths/authsControl'
import { isWhithinTimeToAuth } from '../auths/authsTimeControl'
import {
  authStageName,
  authStageType,
  statusOfStage
} from '../workflow/stagesControl'

export function nextAuthsStatus(request, period, isNextStage) {
  const isAnyAuthNext = isNextStage(authStageType())
  const onTimeToAuth = isWhithinTimeToAuth(period)
  const statusOfAuth = statusOfStage(nameOfAuthsToAction())

  return {
    match: isAnyAuthNext(request) && onTimeToAuth,
    status: statusOfAuth(onTimeToAuth)
  }
}

export function nextAuth1Status(request, period, isNextStage) {
  const auth1 = authStageName(1)
  const isAuth1Next = isNextStage(auth1)
  const onTimeToAuth = isWhithinTimeToAuth(period)
  const statusOfAuth = statusOfStage(auth1)

  return {
    match: isAuth1Next(request) && onTimeToAuth,
    status: statusOfAuth(onTimeToAuth)
  }
}

export function nextAuth2Status(request, period, isNextStage) {
  const auth2 = authStageName(2)
  const isAuth2Next = isNextStage(auth2)
  const onTimeToAuth = isWhithinTimeToAuth(period)
  const statusOfAuth = statusOfStage(auth2)

  return {
    match: isAuth2Next(request) && onTimeToAuth,
    status: statusOfAuth(onTimeToAuth)
  }
}
