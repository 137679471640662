import { sendWebPushToken } from '@/components/messaging/fbApi'
import messagingConfig from '@/services/fbFCMConfig'

export async function setupPushService(messaging, setPushEnabled) {
  // Get registration token from the network for the first time.
  // Then, subsequent calls will return from cache.
  messaging
    .getToken({ vapidKey: messagingConfig.vapidKey })
    .then(async currentToken => {
      if (currentToken) {
        try {
          await sendTokenToServer(currentToken)
          setPushEnabled(true)
        } catch (error) {
          console.log('The current token cound not be sent it.')
        }
      } else {
        setPushEnabled(false)
        setTokenSentToServer(false)
        console.log(
          'No registration token available. Request permission to generate one.'
        )
      }
      // console.log('messaging/token@Login', currentToken)
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err)
      setTokenSentToServer(false)
    })
}

// Send the registration token to your application server, in order to:
// - send messages back to this app
// - subscribe/unsubscribe the token from topics
async function sendTokenToServer(currentToken) {
  if (!isTokenSentToServer() || isGrantRevoked()) {
    console.log('Sending token to server...')
    if (isGrantRevoked()) {
      console.log('Grant was revoked')
      setTokenSentToServer(false)
    }
    await sendWebPushToken(currentToken)
    setTokenSentToServer(true)
  } else {
    // console.log(
    //   "Token already sent to server so won't send it again " +
    //     'unless it changes'
    // )
  }
}

export function isGrantRevoked() {
  return Notification.permission !== 'granted'
}

export function isTokenSentToServer() {
  return window.localStorage.getItem('sentToServer') === '1'
}

function setTokenSentToServer(sent) {
  window.localStorage.setItem('sentToServer', sent ? '1' : '0')
}

export function requestPermission(messaging, setPushEnabled) {
  Notification.requestPermission().then(async permission => {
    if (permission === 'granted') {
      console.log('Notification permission granted.')
      await setupPushService(messaging, setPushEnabled)
    } else {
      console.log('Unable to get permission to notify.')
    }
  })
}

/**
 * Display notification if the focus is in the foreground
 */
export async function displayNotification(payload) {
  if (Notification.permission == 'granted') {
    const notification = payload.notification
    const title = notification.title
    let icon = '/favicon.ico'
    if (notification.icon) {
      icon = !notification.icon ? icon : notification.icon
    }
    const options = {
      body: notification.body,
      icon,
      vibrate: [100, 50, 100]
    }
    navigator.serviceWorker
      .getRegistration('/firebase-cloud-messaging-push-scope')
      .then(registration => {
        console.log(
          'navigator.serviceWorker/registration',
          registration,
          options
        )
        registration
          .showNotification(title, options)
          .then(response => {
            console.log('showNotification successful', response)
          })
          .catch(error => {
            console.log('Pop-up notification failure', error)
          })
      })
  } else {
    // Show the invitation
  }
}

export function deleteToken(messaging, setPushEnabled) {
  messaging
    .getToken()
    .then(currentToken => {
      messaging
        .deleteToken(currentToken)
        .then(async () => {
          console.log('Token deleted.')
          setTokenSentToServer(false)
          await setupPushService(messaging, setPushEnabled)
        })
        .catch(err => {
          console.log('Unable to delete token. ', err)
        })
    })
    .catch(err => {
      console.log('Error retrieving registration token. ', err)
    })
}
