import { es } from 'date-fns/locale'
import {
  addHours,
  addMinutes,
  isBefore,
  isAfter,
  formatDistanceWithOptions
} from 'date-fns/fp'

const ON_TESTING = process.env.NODE_ENV !== 'production' ? true : false
const TEST_VALID_PERIOD_IN_MINUTES = 5
const PRO_NEXT_PERIOD_IN_HOURS = 12
const TEST_PERIOD_BEFORE_DUE_IN_MINUTES = 1
const PRO_PERIOD_BEFORE_DUE_IN_HOURS = 2

// Is valid pass?
export function isPassWithinValidTime(now, effectiveDate) {
  const isBeforeNextPeriod = isBefore(nextPeriod(effectiveDate))
  return isBeforeNextPeriod(now)
}
const nextPeriod = effectiveDate =>
  ON_TESTING ? addTestTime(effectiveDate) : addProTime(effectiveDate)
const addTestTime = date =>
  addMinutes(TEST_VALID_PERIOD_IN_MINUTES - TEST_PERIOD_BEFORE_DUE_IN_MINUTES)(
    date
  )
const addProTime = date =>
  addHours(PRO_NEXT_PERIOD_IN_HOURS - PRO_PERIOD_BEFORE_DUE_IN_HOURS)(date)

// Is valid time to generate new pass
export function isValidPeriodToNewPass(now, effectiveDate) {
  const isAfterNextPeriod = isAfter(nextPeriod(effectiveDate))
  return isAfterNextPeriod(now)
}

// Remaining time to generate new pass
export function remainingTimeToNewPass(now, effectiveDate) {
  const distanceToNow = currentDistanceAsHuman(now)
  return distanceToNow(nextPeriod(effectiveDate))
}
function currentDistanceAsHuman(date) {
  const asHuman = formatDistanceWithOptions({ locale: es, addSuffix: true })
  return asHuman(date)
}
