import {
  areSomeAuthsRejected,
  currentAuths
} from '@/components/requests/auths/authsControl'

/**
 * It returns the stage status
 * @param {Object} stage The required stage
 * @returns A string representing the status
 */
import {
  isAuthStage,
  isNextStage
} from '@/components/requests/workflow/stagesControl'
import { stageStatus } from '@/components/requests/status/statusControl'
export function statusOf(stage) {
  const isAuth = isAuthStage(stage)
  if (isAuth) return stageStatus(stage)
}

/**
 * It return the current workflow status
 * @param {Object} item The required item
 * @param {Object} store A vuex store
 * @returns A string representing the status
 */
export function currentStatus(item, store) {
  // Rejected
  if (areSomeAuthsRejected(currentAuths(item))) return 'rejected'

  // Is the next the current Auth1
  const isNextAuth = store.getters['workflows/willNextStageAuth'](item)
  if (isNextAuth) return 'to_auth'

  // Is End
  const isEnd = store.getters['workflows/isCurrentEndStage'](item)
  if (isEnd) return 'end'

  return 'error'
}

export function isCurrentStatusRejected(item, store) {
  return currentStatus(item, isNextStage(store)) === 'rejected'
}
