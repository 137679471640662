import { isWhithinTimeToAuth3 } from '../auths/auths3TimeControl'
import { authStageName, statusOfStage } from '../workflow/stagesControl'

export function nextAuth3Status(request, period, isNextStage) {
  const isNextAuth3 = isNextStage(authStageName(3))
  const onTimeToAuth3 = isWhithinTimeToAuth3(period)
  const statusOfAuth3 = statusOfStage(authStageName(3))

  return {
    match: isNextAuth3(request) && onTimeToAuth3,
    status: statusOfAuth3(onTimeToAuth3)
  }
}
