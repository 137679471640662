import { getCompletedSections, setCompletedSections } from '@/fbApi'
import { removeItem } from '../nativeStorage'
import { setStorageName } from '../storageName'
import { getJsonItem } from '@/store/nativeStorage'
import { isDef } from '@/micro/utils'

export const namespaced = true

const localRecord = getJsonItem('user') || {
  user: null,
  areSectionsInit: false,
  pushEnabled: true
}

export const state = {
  ...localRecord
}

// export const state = {
//   user: null,
//   areSectionsInit: false,
//   pushEnabled: true
// }

export const getters = {
  user(state) {
    return state.user
  },
  name(state) {
    return state.user.displayName
  },
  isLoggedIn(state) {
    return !!state.user
  },
  username(state) {
    return state.user.email
  },
  area(state) {
    return state.user.aid
  },
  areas(state) {
    return state.user.areas
  },
  areaName(state) {
    return state.user.aname || ''
  },
  contextPath({ getters }) {
    return `${getters.relationship}/${getters.ou}/${getters.area}`
  },
  ou(state) {
    return state.user.o
  },
  relationship(state) {
    return state.user.l
  },
  isEmployee(state) {
    return state.user.l === 'a'
  },
  isProvider(state) {
    return state.user.l === 'b'
  },
  roles(state) {
    return state.user.r
  },
  isSupervisor(state, getters) {
    return getters.hasRole('d')
  },
  isHeadOfSecurity(state, getters) {
    return getters.hasRole('h')
  },
  isSecurityAssistant(state, getters) {
    return getters.hasRole('g')
  },
  isConsultant(state, getters) {
    return getters.hasRole('f')
  },
  isSomeReviewer(state, getters) {
    return (
      getters.isSupervisor || getters.isHeadOfSecurity || getters.isConsultant
    )
  },
  hasRole(state) {
    return role =>
      isDef(state.user) && state.user.r.find(r => r === role) !== undefined
  },
  uid(state) {
    return state.user.uid
  },
  isSectionCompleted(state) {
    return section => {
      const code = {
        profile: 'a',
        documents: 'b'
      }
      return (
        !!state.user &&
        state.user.s.find(s => s === code[section]) !== undefined
      )
    }
  },
  areSectionsRequiredCompleted(state, getters) {
    return (
      getters.isSectionCompleted('profile') &&
      getters.isSectionCompleted('documents')
    )
  },
  isAppInit(state) {
    return !!state.user && state.areSectionsInit
  },
  sections(state) {
    return state.user ? state.user.s : []
  },
  context(state, getters) {
    return {
      rs: getters.relationship,
      ou: getters.ou,
      area: getters.area
    }
  }
}

export const mutations = {
  // SESSION
  SET_USER_SESSION(state, user) {
    if (user.email && user.appId) {
      setStorageName(user.email, user.appId)
      state.user = user
    }
  },
  UPDATE_ORG(state, o) {
    state.user.o = o
  },
  UPDATE_ROLES(state, r) {
    state.user.r = r
  },
  INIT_COMPLETED_SECTIONS(state, sections) {
    state.user.s = sections
    state.areSectionsInit = true
  },
  // ADD_SECTION_COMPLETED(state, section) {
  //   const found = state.user.s.find(s => s === section) !== undefined
  //   if (!found) {
  //     const copy = [...state.user.s]
  //     state.user.s.push(section)
  //     setCompletedSections(state.user.uid, state.user.s).catch(error => {
  //       state.user.s = copy
  //       console.log('can not set completed section', error)
  //     })
  //   }
  // },
  ADD_SECTION_COMPLETED(state, section) {
    state.user.s.push(section)
  },
  RESTORE_SECTIONS_COMPLETED(state, sections) {
    state.user.s = sections
  },
  REMOVE_SECTION_COMPLETED(state, section) {
    const index = state.user.s.findIndex(s => s === section)
    if (index >= 0) {
      const copy = [...state.user.s]
      state.user.s.splice(index, 1)
      setCompletedSections(state.user.uid, state.user.s).catch(error => {
        state.user.s = copy
        console.log('can not set completed section', error)
      })
    }
  },
  UNSET_USER_SESSION() {
    // localStorage.clear()
    removeItem('user')
    removeItem('resources')
    removeItem('requirements')
    state.user = null
    state.pushEnabled = true
  },
  SET_PUSH_ENABLED(state, enabled) {
    state.pushEnabled = enabled
  }
}

export const actions = {
  logout({ commit }) {
    commit('UNSET_USER_SESSION')
  },

  // Deprecated
  // initAuth({ commit }, { l, callback }) {
  //   firebase.auth().onAuthStateChanged(
  //     async user => {
  //       if (user && firebase.auth().currentUser) {
  //         // user obj uses camelCase
  //         try {
  //           const accessToken = await user.getIdToken()
  //           // claims obj uses snake_case
  //           const tokenResult = await firebase
  //             .auth()
  //             .currentUser.getIdTokenResult()
  //           const claims = tokenResult.claims
  //           // @todo Setup the backend to send the app claim
  //           const accountDetails = {
  //             displayName: user.displayName,
  //             email: user.email,
  //             uid: user.uid,
  //             accessToken,
  //             providerData: user.providerData,
  //             appId: 'av',
  //             o: claims.o,
  //             r: claims.r || [],
  //             s: claims.s || [],
  //             l: claims.l || l
  //           }
  //           commit('SET_USER_SESSION', accountDetails)
  //           commit('INIT_COMPLETED_SECTIONS', user.uid)
  //           callback()
  //         } catch (error) {
  //           console.log(error)
  //         }
  //       } else {
  //         commit('UNSET_USER_SESSION')
  //       }
  //     },
  //     error => {
  //       console.log(error)
  //     }
  //   )
  // },

  updateOrg({ commit }, o) {
    commit('UPDATE_ORG', o)
  },

  updateRoles({ commit }, r) {
    commit('UPDATE_ROLES', r)
  },

  // addSectionCompleted({ commit }, section) {
  //   commit('ADD_SECTION_COMPLETED', section)
  // },

  addSectionCompleted({ state, commit, getters }, section) {
    const found = getters.sections.find(s => s === section) !== undefined
    if (!found) {
      const copy = [...getters.sections]
      commit('ADD_SECTION_COMPLETED', section)
      setCompletedSections(state.user.uid, getters.sections).catch(error => {
        commit('RESTORE_SECTIONS_COMPLETED', copy)
        console.log('can not set completed section', error)
      })
    }
  },

  removeSectionCompleted({ commit }, section) {
    commit('REMOVE_SECTION_COMPLETED', section)
  },

  initUserSession({ commit }, accountDetails) {
    commit('SET_USER_SESSION', accountDetails)
  },

  async setCompletedSections({ commit }, uid) {
    const sections = await getCompletedSections(uid)
    commit('INIT_COMPLETED_SECTIONS', sections)
  },

  setPushEnabled({ commit }, enabled) {
    commit('SET_PUSH_ENABLED', enabled)
  }
}
