import { isDef } from '@/micro/utils'

// Branches
export const workflowInRequest = request => request.workflow
export const stagesInRequest = request => request.workflow.stages
export const resourceInRequest = request => request.resource
export const profileInRequest = request => {
  const party = request.party
  const profile = {
    org: {
      poid: party.o,
      relationship: party.l,
      area: {
        id: party.area,
        name: party.aname
      }
    },
    party: {
      fullname: party.name,
      info: party.info
    }
  }
  return profile
}
export const partyInProfile = profile => profile.party
export const orgInProfile = profile => profile.org

// Leafs
export const scheduleStartInResource = resource =>
  new Date(resource.schedule_start)
export const scheduleEndInResource = resource => new Date(resource.schedule_end)
export const scheduleStartInPass = pass => new Date(pass.schedule_start)
export const scheduleEndInPass = pass => new Date(pass.schedule_end)
export const checkinTimeInPass = pass =>
  isDef(pass.checkin) ? pass.checkin.toDate() : pass.checkin
export const hasCheckedIn = pass => isDef(pass.checkin)

// Params
export function paramToScheduleStart(startOrPeriod) {
  const isStrOrDate =
    typeof startOrPeriod === 'string' || startOrPeriod instanceof Date
  return isStrOrDate ? startOrPeriod : startOrPeriod.start
}
export function paramToScheduleEnd(endOrPeriod) {
  const isStrOrDate =
    typeof endOrPeriod === 'string' || endOrPeriod instanceof Date
  return isStrOrDate ? endOrPeriod : endOrPeriod.end
}
export const periodIn = data => {
  return {
    start: new Date(data.schedule_start),
    end: new Date(data.schedule_end)
  }
}

// Workflow
export const stagesFromRequest = request => workflowInRequest(request).stages
export const hasExpired = stage => stage.status === 'expired'
export const isApproved = stage => stage.status === 'approved'
export const isRejected = stage => stage.status === 'rejected'
export const isError = stage => stage.status === 'error'

// Time
export function whenRequestWasCreatedAt(request) {
  return new Date(request.created_at)
}
