export function isTestEmail(email) {
  return (
    [
      'linebip@gmail.com',
      'ix.comunes@gmail.com',
      'ix.juanmanuel@gmail.com',
      'hi@linebip.com',
      'diego@linebip.com'
    ].find(e => e === email) !== undefined
  )
}
