import { isBetweenAnyAndCheckin } from '../pass/checkinTimeControl'
import { ON_LOADING_NEXT_STATUS_STAGE } from '../status/statusControl'
import { isAuth2Stage } from '../workflow/stagesControl'

export function nextPendingStatus(stage, period) {
  return {
    match: isAuth2Stage(stage) && isBetweenAnyAndCheckin(period.start),
    status: ON_LOADING_NEXT_STATUS_STAGE
  }
}
