import { anyToDate } from '@/micro/time/date'
import {
  isBetweenCreatedAndAndCheckin,
  isWithinTimeToCheckin
} from './pass/checkinTimeControl'
import { isWithinTimeToCheckout } from './pass/checkoutTimeControl'
import { resourceInRequest, periodIn } from './reqsControl'

/**
 * @param {{start: Date, end: Date}} resource
 * @param {string | Date} effective
 * @returns boolean
 */
export function wasInFromStart(isInRangeFnBasedOn, period, effective) {
  return wasIn(isInRangeFnBasedOn, period.start, effective)
}

/**
 * @param {{[field]: string}} resource
 * @param {string | Date} effective
 * @returns boolean
 */
export function wasInFromEnd(isInRangeFnBasedOn, period, effective) {
  return wasIn(isInRangeFnBasedOn, period.end, effective)
}

/**
 * @param {{[field]: string}} resource
 * @param {string | Date} effective
 * @returns boolean
 */
export function wasIn(isInRangeFnBasedOn, schedule, effective) {
  const isIn = isInRangeFnBasedOn(anyToDate(schedule))
  return isIn(anyToDate(effective))
}

export function isRequestInLifeSpan(request) {
  const resource = resourceInRequest(request)
  return (
    isBetweenCreatedAndAndCheckin(request) ||
    isWithinTimeToCheckin(periodIn(resource)) ||
    isWithinTimeToCheckout(periodIn(resource))
  )
}
