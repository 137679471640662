import {
  warningDurationToAuth3,
  dangerDurationToAuth3,
  expectedDurationToAuth3
} from '@/cfg'
import { addMinutes, isAfter, isWithinInterval } from 'date-fns/fp'
import { wasInFromStart } from '@/components/requests/reqsTimeControl'

/**
 * @param {{start: Date, end: Date}} period
 * @returns boolean
 */
export function isWhithinTimeToAuth3(period) {
  return (
    isOnExpectedTimeToAuth3(period) ||
    isOnWarningTimeToAuth3(period) ||
    isOnDangerTimeToAuth3(period)
  )
}

/**
 * @param {{start: Date, end: Date}} period
 * @param {string | Date} effective
 * @returns boolean
 */
export function wasWithinTimeToAuth3(period, effective) {
  return (
    wasOnExpectedTimeToAuth3(period, effective) ||
    wasOnWarningTimeToAuth3(period, effective) ||
    wasOnDangerTimeToAuth3(period, effective)
  )
}

export function isOnLateTimeToAuth3(period) {
  return (
    wasOnWarningTimeToAuth3(period, new Date()) ||
    wasOnDangerTimeToAuth3(period, new Date())
  )
}

export function wasOnLateTimeToAuth3(period, effective) {
  return (
    wasOnWarningTimeToAuth3(period, effective) ||
    wasOnDangerTimeToAuth3(period, effective)
  )
}

/**
 * @param {{start: Date, end: Date}} period
 * @returns boolean
 */
export function isOnExpectedTimeToAuth3(period) {
  return wasOnExpectedTimeToAuth3(period, new Date())
}

/**
 * @param {{[field]: string}} resource
 * @param {string | Date} effective
 * @returns boolean
 */
export function wasOnExpectedTimeToAuth3(period, effective) {
  return wasInFromStart(expectedSegmentToAuth3, period, effective)
}

/**
 * @param {{start: Date, end: Date}} period
 * @returns boolean
 */
export function isOnWarningTimeToAuth3(period) {
  return wasOnWarningTimeToAuth3(period, new Date())
}

/**
 * @param {{start: Date, end: Date}} period
 * @param {string | Date} effective
 * @returns boolean
 */
export function wasOnWarningTimeToAuth3(period, effective) {
  return wasInFromStart(warningSegmentToAuth3, period, effective)
}

/**
 * @return boolean
 */
export function isOnDangerTimeToAuth3(period) {
  return wasOnDangerTimeToAuth3(period, new Date())
}

/**
 * @param {{start: Date, end: Date}} period
 * @param {string | Date} effective
 * @returns boolean
 */
export function wasOnDangerTimeToAuth3(period, effective) {
  return wasInFromStart(dangerSegmentToAuth3, period, effective)
}

/**
 * @return boolean
 */
export function isOnInvalidTimeToAuth3(period) {
  return wasOnInvalidTimeToAuth3(period, new Date())
}

/**
 * @param {{[field]: string}} resource
 * @param {string | Date} effective
 * @returns boolean
 */
export function wasOnInvalidTimeToAuth3(period, effective) {
  return wasInFromStart(invalidTimeToAuth3, period, effective)
}

/**
 * @param {Date} scheduleStart
 * @returns curriedFn1
 */
function expectedSegmentToAuth3(scheduleStart) {
  const toExpected = addMinutes(expectedDurationToAuth3())
  const end = toExpected(scheduleStart)
  return isWithinInterval({ start: scheduleStart, end })
}

/**
 * @param {Date} scheduleStart
 * @returns boolean
 */
function warningSegmentToAuth3(scheduleStart) {
  const fromExpected = addMinutes(expectedDurationToAuth3())
  const start = fromExpected(scheduleStart)
  const toWarning = addMinutes(
    expectedDurationToAuth3() + warningDurationToAuth3()
  )
  const end = toWarning(scheduleStart)
  return isWithinInterval({ start, end })
}

/**
 * @param {Date} scheduleStart
 * @returns boolean
 */
function dangerSegmentToAuth3(scheduleStart) {
  const fromWarning = addMinutes(
    expectedDurationToAuth3() + warningDurationToAuth3()
  )
  const start = fromWarning(scheduleStart)
  const toDanger = addMinutes(
    expectedDurationToAuth3() +
      warningDurationToAuth3() +
      dangerDurationToAuth3()
  )
  const end = toDanger(scheduleStart)
  return isWithinInterval({ start, end })
}

/**
 * @param {Date} scheduleStart
 * @returns boolean
 */
function invalidTimeToAuth3(scheduleStart) {
  const fromInvalidTime = addMinutes(
    expectedDurationToAuth3() +
      warningDurationToAuth3() +
      dangerDurationToAuth3()
  )
  return isAfter(fromInvalidTime(scheduleStart))
}
