import { isPassStage } from '@/components/requests/workflow/stagesControl'
import { isOnLateTimeToAuth } from '@/components/requests/auths/authsTimeControl'
import { periodIn, resourceInRequest } from '@/components/requests/reqsControl'
import {
  isStageOnAlert,
  StatusForPendingAlert,
  StatusForPendingRegular
} from './stageOnAlert'

export const REJECTED_REQUEST = 'rejected'
export const APPROVED_STAGE = 'approved'
export const TO_AUTH1_STAGE = 'to_auth1'
export const TO_AUTH2_STAGE = 'to_auth2'
export const TO_AUTH3_STAGE = 'to_auth3'
export const TO_AUTH1_DUE_STAGE = 'to_auth1_due'
export const TO_AUTH2_DUE_STAGE = 'to_auth2_due'
export const TO_AUTH3_DUE_STAGE = 'to_auth3_due'
export const ON_PASS1_STAGE = 'pass1' //@todo add the case to the workflow styles
export const TO_CHECKIN_STAGE = 'to_checkin'
export const TO_CHECKIN_DUE_STAGE = 'to_checkin_due'
export const ON_CHECKIN_NO_SHOW = 'no_show'
export const ON_REQUEST_CANCELED = 'canceled' //@todo add the case to the workflow styles
export const ON_PASS2_STAGE = 'pass2' //@todo add the case to the workflow styles
export const ON_CHECKIN_STAGE = 'checkin'
export const ON_CHECKOUT_STAGE = 'checkout'
export const ON_EXPIRED_REQUEST = 'expired'
export const ON_LOADING_NEXT_STATUS_STAGE = 'loading'
export const ON_UNMATCH_STATUS_ERROR = 'error'

// const isStatus = status => toCheckStatus => status === toCheckStatus
// const isStatusForRequestRejected = isStatus(REJECTED_REQUEST)
// const isStatusStageApproved = isStatus(APPROVED_STAGE)
// const isStatusRequestToAuths = isStatus(TO_AUTH1_STAGE)
// const isStatusRequestToAuth3 = isStatus(TO_AUTH3_STAGE)

/**
 * It returns the next stage status
 * @param {Object} stage The required stage
 * @param {Object} item The required item
 * @returns A string representing the status
 * @tag isOnLateTimeToAuth
 */
export function nextStatusOf(stage, item) {
  return isStageOnAlert(stage) ||
    isOnLateTimeToAuth(periodIn(resourceInRequest(item)))
    ? StatusForPendingAlert
    : StatusForPendingRegular
}

export function stageStatus(stage) {
  return stage.status
}

export function isAuthSuccessful(stage) {
  return isStageApproved(stage)
}

export function isStageApproved(stage) {
  return (
    stage && (stage.status === 'approved' || stage.status === 'approved_alert')
  )
}

export function stageStatusWithAlert(stage) {
  return `${stage.status}_alert`
}

export function wasStagePassActive(stage, isAuth) {
  return !isAuth && isPassStage(stage)
}

export function isNextStagePass1(stage) {
  return stage.next === 'pass1'
}

export function isNextStagePass2(stage) {
  return stage.next === 'pass2'
}

export function isNextStagePass(stage) {
  return isNextStagePass1(stage) || isNextStagePass2(stage)
}

import { isCurrentStatusRejected as employeeOneIsCurrentStatusRejected } from '@/components/requests/employees/oneAccessStatus'
import { isCurrentStatusRejected as employeeContinuosIsCurrentStatusRejected } from '@/components/requests/employees/continuosAccessStatus'
import { isCurrentStatusRejected as employeeFilesIsCurrentStatusRejected } from '@/components/requests/employees/accessDocsStatus'
export function isCurrentStatusRejectedOnWorkflowFor(item) {
  if (item.workflow.id === 'eoa') return employeeOneIsCurrentStatusRejected
  if (item.workflow.id === 'eca')
    return employeeContinuosIsCurrentStatusRejected
  if (item.workflow.id === 'efa') return employeeFilesIsCurrentStatusRejected
}

import { currentStatus as employeeOneCurrentStatus } from '@/components/requests/employees/oneAccessStatus'
import { currentStatus as employeeContinuosCurrentStatus } from '@/components/requests/employees/continuosAccessStatus'
import { currentStatus as employeeFilesCurrentStatus } from '@/components/requests/employees/accessDocsStatus'
export function currentStatusOnWorkflowFor(item) {
  if (item.workflow.id === 'eoa') return employeeOneCurrentStatus
  if (item.workflow.id === 'eca') return employeeContinuosCurrentStatus
  if (item.workflow.id === 'efa') return employeeFilesCurrentStatus
}

import { statusOf as employeeOneStatusOf } from '@/components/requests/employees/oneAccessStatus'
import { statusOf as employeeContinuosStatusOf } from '@/components/requests/employees/continuosAccessStatus'
import { statusOf as employeeFilesStatusOf } from '@/components/requests/employees/accessDocsStatus'
export function statusOnWorkflowFor(item) {
  if (item.workflow.id === 'eoa') return employeeOneStatusOf
  if (item.workflow.id === 'eca') return employeeContinuosStatusOf
  if (item.workflow.id === 'efa') return employeeFilesStatusOf
}
