import { firebaseApp } from '@/firebaseInit'
import { format } from 'date-fns/fp'
import {
  getFirestore,
  writeBatch,
  serverTimestamp,
  increment,
  collection,
  doc,
  onSnapshot
} from 'firebase/firestore'

const PUB_PATH_SEGMENT = 'pub'
const DOMAIN_PATH_SEGMENT = 'covid19_op'
const userPath = oid => `${PUB_PATH_SEGMENT}/${DOMAIN_PATH_SEGMENT}/${oid}`

export async function sendSuccessfulAssessment(
  uid,
  party,
  assessment,
  srcHost,
  updateStore
) {
  const db = getFirestore(firebaseApp)
  const batch = writeBatch(db)

  const oid = party.oid
  const userRef = doc(db, userPath(oid), uid)
  rateLimitByTime(batch)(userRef, serverTimestamp, increment)
  const assessmentId = resolvedAssessmentId(db, oid, uid)
  rateLimitByQuantity(batch)(userRef, assessmentId, serverTimestamp)
  addSuccessfulAssessment(batch, db)(
    uid,
    party,
    assessmentId,
    assessment,
    srcHost
  )
  // keepUpdatedStats(batch, db)(oid, assessmentId, assessment, serverTimestamp)

  await batch.commit()
  return listenPassChanges(updateStore)(uid, oid, assessmentId)
}

export async function sendRejectedAssessment(
  uid,
  party,
  assessment,
  updateStore
) {
  const db = getFirestore(firebaseApp)
  const batch = writeBatch(db)

  const oid = party.oid
  const userRef = doc(db, userPath(oid), uid)
  rateLimitByTime(batch)(userRef, serverTimestamp, increment)
  const assessmentId = resolvedAssessmentId(db, oid, uid)
  rateLimitByQuantity(batch)(userRef, assessmentId, serverTimestamp)
  addFailureAssessment(batch, db)(uid, party, assessmentId, assessment)
  // keepUpdatedStats(batch, db)(oid, assessmentId, assessment, serverTimestamp)

  await batch.commit()
  updateStore({ id: assessmentId, results: assessment.results })
}

function rateLimitByTime(batch) {
  return (userRef, serverTimestamp, increment) =>
    batch.set(
      userRef,
      {
        lastAssessment: serverTimestamp(),
        assessmentCount: increment(1)
      },
      { merge: true }
    )
}

function rateLimitByQuantity(batch) {
  return (userRef, assessmentId, serverTimestamp) =>
    batch.set(
      userRef,
      {
        assessments: {
          [assessmentId]: serverTimestamp()
        }
      },
      { merge: true }
    )
}

function addSuccessfulAssessment(batch, db) {
  return (uid, party, assessmentId, assessment, srcHost) => {
    const assessmentRef = doc(
      db,
      `${userPath(party.oid)}/${uid}/assessments/${assessmentId}`
    )
    return batch.set(assessmentRef, {
      party,
      ...assessment,
      createdAt: serverTimestamp(),
      result: assessment.result,
      srcHost
    })
  }
}

function addFailureAssessment(batch, db) {
  return (uid, party, assessmentId, assessment) => {
    const assessmentRef = doc(
      db,
      `${userPath(party.oid)}/${uid}/assessments/${assessmentId}`
    )
    return batch.set(assessmentRef, {
      party,
      ...assessment,
      createdAt: serverTimestamp(),
      result: assessment.result
    })
  }
}

// @todo send it to the server
// function keepUpdatedStats(batch, db) {
//   return (oid, assessmentId, assessment, serverTimestamp) => {
//     const opRef = doc(db, PUB_PATH_SEGMENT, DOMAIN_PATH_SEGMENT)
//     const isAssessmentApproved = assessment => assessment.result === 'APPROVED'
//     const approved = isAssessmentApproved(assessment)
//       ? { approved: increment(1) }
//       : {}
//     return batch.set(
//       opRef,
//       {
//         [oid]: {
//           [assessmentId]: {
//             lastUpdated: serverTimestamp(),
//             count: increment(1),
//             ...approved
//           }
//         }
//       },
//       { merge: true }
//     )
//   }
// }

export function listenPassChanges(updateStore) {
  return (uid, oid, assessmentId) => {
    const db = getFirestore(firebaseApp)
    const unsubscribe = onSnapshot(
      doc(db, `${userPath(oid)}/${uid}/assessments`, assessmentId),
      document => {
        updateStore(document.data())
      }
    )
    return unsubscribe
  }
}

function resolvedAssessmentId(db, oid, uid) {
  const isDebugOn = process.env.NODE_ENV !== 'production' ? true : false
  return isDebugOn ? assessmentIdOnDebug(db, oid, uid) : assessmentIdOnPro()
}

function assessmentIdOnPro() {
  const assessmentIdByDayOfYear = format('DDD')
  return assessmentIdByDayOfYear(new Date())
}

function assessmentIdOnDebug(db, oid, uid) {
  const ref = doc(collection(db, `${userPath(oid)}/${uid}/assessments`))
  return ref.id
}
