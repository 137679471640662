import 'firebase/firestore'
import {
  isEmployee,
  isProvider
} from '@/components/requests/workflow/conditions/assignmentsConditions'
import { docsApproved } from '@/components/requests/workflow/conditions/docsConditions'
import { projectOpen } from '@/components/requests/workflow/conditions/providersConditions'
import {
  healthApprovalInDuration,
  healthApprovalNotRequired
} from '@/components/requests/workflow/conditions/healthConditions'
import {
  checkinAheadSchedule,
  checkinOnSchedule,
  checkinBehindSchedule,
  checkoutAheadSchedule,
  checkoutOnSchedule,
  checkoutBehindSchedule,
  checkoutException,
  checkoutWithoutAuth3,
  hasConsultantRole,
  hasSecurityAssistantRole
} from '@/components/requests/workflow/conditions/checksConditions'

export const conditionsDic = [
  // Preconditions
  { name: 'isEmployee', fn: isEmployee, async: false },
  { name: 'isProvider', fn: isProvider, async: false },
  { name: 'docsApproved', fn: docsApproved, async: false },

  // Providers
  { name: 'projectOpen', fn: projectOpen, async: true },

  // Auths
  {
    name: 'hasConsultantRole',
    fn: hasConsultantRole,
    async: false
  },

  // Health
  {
    name: 'healthApprovalInDuration',
    fn: healthApprovalInDuration,
    async: true
  },
  {
    name: 'healthApprovalNotRequired',
    fn: healthApprovalNotRequired,
    async: true
  },

  // Checkin
  {
    name: 'hasSecurityAssistantRole',
    fn: hasSecurityAssistantRole,
    async: false
  },
  {
    name: 'checkoutWithoutAuth3',
    fn: checkoutWithoutAuth3,
    async: false
  },
  {
    name: 'checkinAheadSchedule',
    fn: checkinAheadSchedule,
    async: false
  },
  {
    name: 'checkinOnSchedule',
    fn: checkinOnSchedule,
    async: false
  },
  {
    name: 'checkinBehindSchedule',
    fn: checkinBehindSchedule,
    async: false
  },

  // Checkout
  {
    name: 'checkoutAheadSchedule',
    fn: checkoutAheadSchedule,
    async: false
  },
  {
    name: 'checkoutOnSchedule',
    fn: checkoutOnSchedule,
    async: false
  },
  {
    name: 'checkoutBehindSchedule',
    fn: checkoutBehindSchedule,
    async: false
  },
  {
    name: 'checkoutException',
    fn: checkoutException,
    async: false
  }
]
