<template>
  <v-snackbar
    v-model="requiresPermission"
    :timeout="-1"
    absolute
    left
    shaped
    top
  >
    ¿Deseas recibir notificaciones?

    <template v-slot:action="{ attrs }">
      <v-btn color="pink" text v-bind="attrs" @click="askPermission()">
        Si
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { requestPermission } from '@/services/fbPushCtl'
export default {
  props: {
    requiresPermission: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    askPermission() {
      requestPermission(this.$messaging, this.setPushEnabled)
    }
  }
}
</script>
