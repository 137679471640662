import { firebaseApp } from '@/firebaseInit'
import {
  getFirestore,
  writeBatch,
  serverTimestamp,
  increment,
  doc
} from 'firebase/firestore'

const PUB_PATH_SEGMENT = 'pub'
const DOMAIN_PATH_SEGMENT = 'covid19_op'
const userPath = oid => `${PUB_PATH_SEGMENT}/${DOMAIN_PATH_SEGMENT}/${oid}`

export async function sendRequestForCall(uid, party, assessmentId, assessment) {
  const db = getFirestore(firebaseApp)
  const batch = writeBatch(db)

  const oid = party.oid
  const userRef = doc(db, userPath(oid), uid)
  rateLimitByTime(batch)(userRef, serverTimestamp, increment)
  rateLimitByQuantity(batch)(userRef, assessmentId, serverTimestamp)
  addRequestForCall(batch, db)(uid, party, assessmentId, assessment)

  await batch.commit()
}

function rateLimitByTime(batch) {
  return (userRef, serverTimestamp, increment) =>
    batch.set(
      userRef,
      {
        lastMessage: serverTimestamp(),
        messageCount: increment(1)
      },
      { merge: true }
    )
}

function rateLimitByQuantity(batch) {
  return (userRef, messageId, serverTimestamp) =>
    batch.set(
      userRef,
      {
        messages: {
          [messageId]: serverTimestamp()
        }
      },
      { merge: true }
    )
}

function addRequestForCall(batch, db) {
  return (uid, party, assessmentId, assessment) => {
    const messageRef = doc(
      db,
      `${userPath(
        party.oid
      )}/${uid}/assessments/${assessmentId}/messages/${assessmentId}`
    )
    return batch.set(messageRef, {
      party,
      ...assessment,
      createdAt: serverTimestamp(),
      result: assessment.result
    })
  }
}
