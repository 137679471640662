import {
  isOnEarlierTimeToCheckin,
  isOnExpectedTimeToCheckin,
  isOnLateTimeToCheckin
} from '@/components/requests/pass/checkinTimeControl'
import {
  isCheckoutAheadSchedule,
  isInUnexpectedTimeToCheckoutFromRequest,
  isOnExpectedTimeToCheckout,
  isOnLateTimeToCheckout
} from '@/components/requests/pass/checkoutTimeControl'
import {
  periodIn,
  resourceInRequest,
  stagesInRequest
} from '@/components/requests/reqsControl'
import { isPass2NoIssued } from '@/components/requests/workflow/stagesControl'

// Checkin
export const checkinAheadSchedule = ({ request }) =>
  isOnEarlierTimeToCheckin(periodIn(resourceInRequest(request)))

export const checkinOnSchedule = ({ request }) =>
  isOnExpectedTimeToCheckin(periodIn(resourceInRequest(request)))

export const checkinBehindSchedule = ({ request }) =>
  isOnLateTimeToCheckin(periodIn(resourceInRequest(request)))

// Checkout
export const checkoutAheadSchedule = ({ request }) => {
  const period = periodIn(resourceInRequest(request))
  return isCheckoutAheadSchedule(period.start, period.end)
}

export const checkoutOnSchedule = ({ request }) => {
  return isOnExpectedTimeToCheckout(periodIn(resourceInRequest(request)))
}

export const checkoutBehindSchedule = ({ request }) => {
  return isOnLateTimeToCheckout(periodIn(resourceInRequest(request)))
}

export const checkoutException = ({ request }) => {
  return isInUnexpectedTimeToCheckoutFromRequest(request)
}

export const checkoutWithoutAuth3 = ({ request }) => {
  return isPass2NoIssued(stagesInRequest(request))
}

export const hasSecurityAssistantRole = ({ rootGetters }) => {
  const hasAllowedRole = rootGetters['user/isSecurityAssistant']
  return hasAllowedRole
}

export const hasConsultantRole = ({ rootGetters }) => {
  const hasAllowedRole = rootGetters['user/isConsultant']
  return hasAllowedRole
}
