import Login from '@/views/Login.vue'

export default [
  {
    path: '/login',
    alias: '/',
    name: 'Login',
    component: Login,
    meta: { guest: true }
  },
  {
    path: '/loading',
    name: 'Loading',
    component: () =>
      import(/* webpackChunkName: "loading" */ '../views/Loading.vue'),
    meta: { guest: true }
  },
  {
    path: '/onverification',
    name: 'OnVerification',
    component: () =>
      import(/* webpackChunkName: "signup" */ '../views/OnVerification.vue'),
    meta: { guest: true }
  },
  {
    path: '/V5eSULS383wHBRF134pi',
    name: 'CovidTermsOperator',
    component: () =>
      import(
        /* webpackChunkName: "op" */ '../components/CovidTermsOperator.vue'
      ),
    meta: { guest: true }
  },
  {
    path: '/provider',
    name: 'Provider',
    component: () =>
      import(/* webpackChunkName: "provider" */ '../views/Provider.vue'),
    meta: { guest: true }
  },
  {
    path: '/check/:id',
    name: 'PassCode',
    component: () =>
      import(/* webpackChunkName: "passcode" */ '../views/PassCode.vue'),
    meta: { auth: true, group: 'party' }
  },
  {
    path: '/requests',
    name: 'Requests',
    component: () =>
      import(/* webpackChunkName: "requests" */ '../views/Requests.vue'),
    meta: { auth: true, group: 'party' }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: { auth: true, group: 'employee' }
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () =>
      import(/* webpackChunkName: "documents" */ '../views/Documents.vue'),
    meta: { auth: true, group: 'party' }
  },
  {
    path: '/request',
    name: 'Request',
    component: () =>
      import(/* webpackChunkName: "request" */ '../views/Request.vue'),
    meta: { auth: true, group: 'party' }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: Login,
    meta: { guest: true }
  }
]
