import { isCanceledStage, isCheckinStage } from '../workflow/stagesControl'
import { ON_REQUEST_CANCELED } from '../status/statusControl'

export function checkinCanceledSatus(stage) {
  const isCurrentCheckin = isCheckinStage(stage)
  const isCanceled = isCurrentCheckin && isCanceledStage(stage)

  return {
    match: isCurrentCheckin && isCanceled,
    status: ON_REQUEST_CANCELED
  }
}
