import {
  getPolicyDocPath,
  getUserPolicyPath
} from '@/store/modules/conditionsApi/fbReadPolicies'
import firebase from 'firebase/compat/app'
import { conditionsDic } from './conditionsDic'
/**
 * Evaluates and resolves a codintion as true or false
 * @param {Function} conditionId the condition expressed as Fn
 * @param {Object} context { stage: object, request: object, rootGetters: vuex }
 * @returns Boolean
 * @throws
 */
export async function conditionAssessment(conditionName, context) {
  const matched = conditionsDic.find(
    condition => condition.name === conditionName
  )
  if (matched === undefined) {
    throw new Error(`The condition {${conditionName}} does not exists`)
  } else {
    return matched.async
      ? await matched.fn(context)
      : await Promise.resolve(matched.fn(context))
  }
}

export const generalPolicyByTopic = async (topic, rootGetters) => {
  const context = rootGetters['user/context']
  const docPath = `${getPolicyDocPath(context)}`
  const policyRef = firebase.firestore().doc(docPath)
  return (await policyRef.get()).get(topic)
}

export const userPolicyCondition = async (
  condition,
  topic,
  request,
  rootGetters
) => {
  const context = rootGetters['user/context']
  const path = getUserPolicyPath(request.uid, topic, context)
  const query = firebase
    .firestore()
    .collection(path)
    .where(condition, '==', true)
  return await query.get()
}
